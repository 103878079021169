import { Font, StyleSheet } from '@react-pdf/renderer';

import plexSans from 'assets/fonts/IBMPlexSans-Regular.woff';
import plexSansLight from 'assets/fonts/IBMPlexSans-Light.woff';
import plexSansCondensed from 'assets/fonts/IBMPlexSansCond-Regular.woff';
import plexSansBold from 'assets/fonts/IBMPlexSans-Bold.woff';
import plexSansSemiBold from 'assets/fonts/IBMPlexSans-SemiBold.woff';

const primaryMain = 'rgb(15, 76, 129)';
const primaryMid = 'rgb(135, 166, 192)';
const primaryLight = 'rgb(226, 233, 239)';
const textDark = 'rgb(97, 97, 97)';
const paper = '#ffffff';

// * PLEX CONDENSED
Font.register({
    family: 'IBM Plex Sans Condensed',
    src: plexSansCondensed,
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal'
});
// * PLEX SANS
Font.register({
    family: 'IBM Plex Sans',
    src: plexSansSemiBold,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal'
});
Font.register({
    family: 'IBM Plex Sans',
    src: plexSansLight,
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal'
});
Font.register({
    family: 'IBM Plex Sans',
    src: plexSans,
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal'
});
Font.register({
    family: 'IBM Plex Sans',
    src: plexSansBold,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal'
});

const brochureStyles = StyleSheet.create({
    // * LAYOUT
    page: {
        backgroundColor: paper,
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    darkPage: {
        backgroundColor: primaryMain,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    block: {
        flexGrow: 1
    },
    row: {
        flexGrow: 1,
        flexDirection: 'row'
    },
    rowBetween: {
        flexGrow: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10
    },
    wide: {
        width: '80%',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    narrow: {
        width: '20%',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    // * BLOCKS
    header: {
        // flexGrow: 2,
        backgroundColor: primaryLight,
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '30px 20px 20px 30px',
        justifyContent: 'center',
        width: '100%'
    },
    body: {
        // flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        // backgroundColor: paper,
        margin: '30px 30px 20px 30px'
    },
    image: {
        width: 200,
        height: 200,
        // height: 'auto',
        margin: 10
    },
    coverText: {
        width: '100%',
        MozHyphens: 'none',
        MsHyphens: 'none',
        WebkitHyphens: 'none',
        hyphens: 'none',
        borderLeft: '1px solid rgb(135, 166, 192)',
        paddingLeft: '20px',
        fontFamily: 'IBM Plex Sans Condensed',
        color: 'rgb(255,255,255)',
        fontSize: 42,
        textAlign: 'left'
    },
    // * TYPOGRAPHY
    title: {
        fontSize: 18,
        textAlign: 'left',
        // backgroundColor: '#e4e4e4',
        color: primaryMain,
        textTransform: 'none',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 500
    },
    subtitle: {
        // margin: 20,
        marginBottom: 10,
        fontSize: 12,
        textAlign: 'left',
        color: primaryMid,
        // textTransform: 'uppercase',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 400
    },
    link: {},
    iconContainer: {
        marginTop: 10,
        width: 32,
        height: 32,
        marginRight: 15,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    moduleIconContainer: {
        background: primaryMain,
        backgroundColor: primaryMain,
        borderRadius: 8,
        width: 32,
        height: 32,
        marginRight: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    moduleIcon: {
        right: 0,
        width: 20,
        height: 20,
        color: paper,
        // color: paper,
        // fontFamily: 'IBM Plex Sans',
        // textAlign: 'right',
        margin: 0

        // marginBottom: '20px'
    },
    moduleLogoContainer: {
        height: 32,
        maxWidth: 50,
        marginLeft: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    moduleLogo: {
        // multiply overlay
        mixBlendMode: 'overlay',
        left: 0,
        width: '100%',
        height: 32,
        minHeight: 32,
        objectFit: 'contain',
        // color: paper,
        // fontFamily: 'IBM Plex Sans',
        // textAlign: 'right',
        margin: 0

        // marginBottom: '20px'
    },
    topicIconContainer: {
        right: 10,
        width: 18,
        height: 18,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

        // marginBottom: '20px'
    },
    topicIcon: {
        right: 0,
        width: 18,
        height: 18,
        color: primaryMain,
        fontFamily: 'IBM Plex Sans',
        textAlign: 'right',
        margin: 0

        // marginBottom: '20px'
    },
    endRow: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%'
    },
    footerLink: {
        fontSize: 8,
        fontWeight: 500,
        color: paper,
        textDecoration: 'underline',
        textAlign: 'right'
    },
    textSubtle: {
        color: primaryMid
    },
    footerNumber: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: primaryMain,
        color: paper,
        padding: '5px 20px 5px 30px',
        fontFamily: 'IBM Plex Sans Condensed',
        fontWeight: 400,
        fontSize: 8,
        // padding: '20px 20px 20px 30px',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0
    },
    introText: {
        // hyphens: 'none !important',
        fontSize: 9,
        lineHeight: 1.5,
        fontWeight: 300,
        color: primaryMain,
        fontFamily: 'IBM Plex Sans',
        textAlign: 'left',
        paddingBottom: '20px'
    },
    'introText strong': {
        fontWeight: 500
    },
    bold: {
        fontWeight: 500
    },
    moduleBody: {
        // margin: 10,
        // hyphens: 'none',
        fontSize: 9,
        lineHeight: 1.5,
        fontWeight: 300,
        color: textDark,
        fontFamily: 'IBM Plex Sans',
        textAlign: 'left',
        marginBottom: '20px'
    },
    heroSection: {
        backgroundColor: primaryMid,
        // padding: '20px 20px 20px 20px',
        left: '20%',
        width: '80%',
        right: 0,
        padding: '20px 20px 20px 30px',
        bottom: 20,
        fontWeight: 500,
        fontSize: 9,
        // height: 202,
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    lightText: {
        // width: '60%',
        // margin: 10,
        fontSize: 10,
        color: paper,
        fontFamily: 'IBM Plex Sans',
        textAlign: 'justify'
    },
    sectionTitle: {
        right: 0,
        marginBottom: 10,
        fontSize: 11,
        borderLeft: `1px solid ${primaryMain}`,
        paddingLeft: 10,
        fontWeight: 600,
        color: primaryMain,
        fontFamily: 'IBM Plex Sans',
        textAlign: 'justify',
        textTransform: 'uppercase'
    },
    darkText: {
        color: textDark,
        // fontSize: 10,
        // fontFamily: 'IBM Plex Sans ',
        textAlign: 'justify'
    }
});

export default brochureStyles;
