import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { useCookies, withCookies } from 'react-cookie';

// routing
import { PublicRoutes } from 'routes';

// defaultTheme
import themes from 'themes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';

import ReactGA from 'react-ga4';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const [cookies, setCookie] = useCookies();

    // console.log('COOKIES', cookies);

    // check if iubenda cookie is set

    useEffect(() => {
        // run tthe following code only on the first render, and on page refresh, not on route change
        const iubendaCookie = cookies['_iub_cs-96972586'];
        if (iubendaCookie) {
            console.log('⚙️✅ Cookies e privacy policy accettati');
            // check if iubendaCookie.purposes object contains all true values
            const purposes = iubendaCookie.purposes;
            const allTrue = Object.values(purposes).every((purpose) => purpose === true);
            if (allTrue) {
                console.log('⚙️✅ Tutti i cookie sono stati accettati');
                // if (process.env.GA_TRACKING_ID) {
                ReactGA.initialize(process.env.GA_TRACKING_ID || 'G-LE19Y2ZS33');
                console.log('⚙️🌎 Tag GA4 inizializzato. Tracking attivo');
            } else {
                console.log('⚙️🚫 Accettati soli cookie necessari');
            }
        }
    }, [cookies]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <PublicRoutes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

App.propTypes = {
    // cookies: PropTypes.instanceOf(Cookies).isRequired
};
export default withCookies(App);
