import ReactDOM from 'react-dom';

import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { store } from 'store';

import 'assets/scss/style.scss';
import { UserProvider } from 'context/UserContext';

const client = new ApolloClient({
    uri: `${process.env.PUBLIC_URL}/graphql`,
    // uri: `http://localhost:3000/graphql`,
    cache: new InMemoryCache(),
    // link: HttpLink,
    connectToDevTools: process.env.NODE_ENV === 'development'
});
// ==============================|| REACT DOM RENDER  ||============================== //
// console.log('client', client);
// console.log('ENVIRONMENT', process.env);
// ReactDOM.hydrate(
ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <CookiesProvider>
                <UserProvider>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <App />
                    </BrowserRouter>
                </UserProvider>
            </CookiesProvider>
        </Provider>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
