// material-ui
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Document, Image, Link, Page, renderToFile, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import brochureStyles from 'ui-component/pdf/brochureStyles';
import { style } from '@mui/system';
// ==============================|| CUSTOM MAIN CARD ||============================== //

const CustomBrochure = ({ mods, recipientName, generationDate }) => {
    const stylesSrc = brochureStyles;
    const theme = useTheme();
    const [styles, setStyles] = useState(stylesSrc || {});
    const [totalPages, setTotalPages] = useState(mods?.length || 0);

    useEffect(() => {
        if (!styles) {
            setStyles(styles);
        }

        if (mods?.length > 0) {
            setTotalPages(mods?.length);
        }
    }, [styles, mods]);

    if (!styles) {
        return null;
    }

    const firstPage = {
        title: 'Pagine pre contenuto',
        subtitle: 'Pagine pre contenuto',
        body: 'Pagine pre contenuto'
    };

    return (
        <Document
            title="Brochure personalizzata Artel"
            author="Artel"
            subject="Brochure personalizzata Artel"
            keywords="Artel, Brochure, PDF"
            creator="Artel Lombardia SRL"
        >
            <Page size="A5" style={styles.darkPage}>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '90%',
                        width: '100%',
                        padding: '40px 20px 40px 40px'
                        // padding: '30px 20px'
                    }}
                >
                    {recipientName && (
                        <View
                            style={{
                                ...styles.footerNumber,
                                position: 'absolute',
                                left: '10px',
                                top: '30px',
                                width: '100%',
                                height: '10%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Text style={{ ...styles.textSubtle, fontSize: 16 }}>
                                Spett. <Text style={{ color: 'rgb(255,255,255)' }}>{recipientName}</Text>
                            </Text>
                        </View>
                    )}
                    <View
                        style={{
                            width: '100%',
                            height: '85%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={styles.coverText}>La tua brochure</Text>
                        <Text style={styles.coverText}>personalizzata</Text>
                        {mods?.length > 0 && <Text style={styles.coverText}>su {mods?.length} argomenti</Text>}
                        {mods?.length > 0 && <Text style={styles.coverText}>di interesse.</Text>}
                    </View>
                    <View
                        style={{
                            ...styles.footerNumber,
                            position: 'absolute',
                            left: '10px',
                            bottom: 0,
                            width: '100%',
                            height: '5%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {generationDate && (
                            <Text style={{ ...style.textSubtle, textAlign: 'left', color: 'rgb(135, 166, 192)' }}>{generationDate}</Text>
                        )}
                        <Image
                            style={(styles.logo, { width: 'auto', height: 15, objectFit: 'contain' })}
                            src={`${process.env.PUBLIC_URL}/images/brand/artel-logotype.jpg`}
                        />
                    </View>
                </View>
            </Page>
            <Page size="A5" style={styles.page}>
                <View
                    style={{
                        ...styles.body,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70%'
                    }}
                >
                    <Text style={styles.introText}>
                        Artel è <Text style={styles.bold}>premium partner</Text> della filiale italiana di{' '}
                        <Text style={styles.bold}>Wolters Kluwer</Text> - società olandese di respiro internazionale - ad oggi presente in
                        Europa, Nord America ed Estremo Oriente, grazie alla qualità della sua offerta. La{' '}
                        <Text style={styles.bold}>missione del gruppo</Text> consiste nel fornire - ad imprese, professionisti e figure
                        aziendali - <Text style={styles.bold}>prodotti/servizi completi e autorevoli</Text>, nel campo dell&apos;editoria e
                        del <Text style={styles.bold}>software</Text>. Le attività strategiche dela società spaziano dal{' '}
                        <Text style={styles.bold}>settore legale, fiscale e aziendale</Text>, all&apos;editoria medico-scientifica ed
                        accademica.
                    </Text>
                    <Text style={styles.introText}>
                        Il brand Artel nasce in Italia nei <Text style={styles.bold}>primi anni &apos;80</Text>, presentandosi al pubblico
                        come una delle prime <Text style={styles.bold}>software house</Text>, specializzata nello sviluppo di soluzioni
                        informatiche e nell&apos;erogazione di <Text style={styles.bold}>servizi alle aziende</Text> di piccole e medie
                        dimensioni - collocate geograficamente <Text style={styles.bold}>nella zona industriale più produttiva</Text>,
                        nell&apos;area del triveneto.
                    </Text>
                    <Text style={styles.introText}>
                        L&apos;<Text style={styles.bold}>elevato standard progettuale</Text>, insieme alla capacità di{' '}
                        <Text style={styles.bold}>customizzare</Text> le soluzioni proposte - per adattarsi alle mutevoli{' '}
                        <Text style={styles.bold}>dinamiche, infrastrutture e necessità</Text> di aziende che operano nei settori più
                        diversi - hanno portato il <Text style={styles.bold}>brand Artel</Text> ad essere leader riconosciuto nello{' '}
                        <Text style={styles.bold}>sviluppo di software gestionali</Text> per le P.M.I. italiane. Lo sviluppo di applicativi
                        - ha come obiettivo il raggiungimento della{' '}
                        <Text style={styles.bold}>massima efficienza funzionale e tecnologica</Text> - e viene effettuato esclusivamente con
                        l&apos;ausilio di strumenti <Text style={styles.bold}>Microsoft</Text>.
                    </Text>
                    <Text style={styles.introText}>
                        Attualmente, sono <Text style={styles.bold}>circa 15.000</Text> le <Text style={styles.bold}>aziende</Text> che in
                        Italia <Text style={styles.bold}>utilizzano Arca Evolution</Text>: la <Text style={styles.bold}>soluzione ERP</Text>{' '}
                        al centro dell&apos;offerta Artel. Per garantire ai propri clienti la{' '}
                        <Text style={styles.bold}>massima copertura</Text> rispetto alle molteplici esigenze aziendali, Artel ha diverse
                        <Text style={styles.bold}> business units</Text> dedicate all&apos;erogazione dei{' '}
                        <Text style={styles.bold}> servizi sistemistici</Text> e dei <Text style={styles.bold}>servizi Cloud</Text>, allo
                        <Text style={styles.bold}> sviluppo software</Text>, all&apos;<Text style={styles.bold}>assistenza</Text> e alla
                        <Text style={styles.bold}> consulenza</Text> in ambito formativo.
                    </Text>
                </View>
                <View style={{ ...styles.rowBetween, height: 'auto', width: 'auto', margin: '0 30px 10px 30px' }}>
                    <Image
                        style={(styles.logo, { width: 'auto', height: 20, objectFit: 'contain' })}
                        src={`${process.env.PUBLIC_URL}/images/brand/wki-logo.jpg`}
                    />
                    <Image
                        style={(styles.logo, { width: 'auto', height: 20, objectFit: 'contain' })}
                        src={`${process.env.PUBLIC_URL}/images/brand/artel-logo.jpg`}
                    />
                </View>
                {/* <View style={styles.footerNumber}>
                    <Text style={styles.textSubtle}>&mdash; 1 / {totalPages}</Text>
                </View> */}
            </Page>
            {mods?.map((mod, index) => (
                <Page key={index} size="A5" style={styles.page}>
                    <View style={styles.header}>
                        <Text style={styles.title}>{mod?.titolo}</Text>
                        {mod?.sottotitolo && <Text style={styles.subtitle}>{mod?.sottotitolo}</Text>}
                        <View style={styles.rowBetween}>
                            <View style={styles.moduleIconContainer}>
                                <Image style={styles.moduleIcon} src={`${process.env.PUBLIC_URL}/images/brochure/${mod?.icona}.jpg`} />
                            </View>
                            {mod?.raster && (
                                <View style={styles.moduleLogoContainer}>
                                    <Image style={styles.moduleLogo} src={mod?.raster} />
                                </View>
                            )}
                        </View>
                    </View>
                    <View style={styles.body}>
                        {mod?.CorpoModulo?.map((block, index) => (
                            <View key={index}>
                                <View style={styles.row}>
                                    <View style={styles.narrow}>
                                        <View style={styles.topicIconContainer}>
                                            {index === 0 && (
                                                <Image
                                                    style={styles.topicIcon}
                                                    src={`${process.env.PUBLIC_URL}/images/brochure/la-tua-esigenza.jpg`}
                                                />
                                            )}
                                            {index === 1 && (
                                                <Image
                                                    style={styles.topicIcon}
                                                    src={`${process.env.PUBLIC_URL}/images/brochure/la-nostra-soluzione.jpg`}
                                                />
                                            )}
                                            {index === 2 && (
                                                <Image
                                                    style={styles.topicIcon}
                                                    src={`${process.env.PUBLIC_URL}/images/brochure/che-cosa-ottieni.jpg`}
                                                />
                                            )}
                                        </View>
                                    </View>
                                    <View style={styles.wide}>
                                        <View>
                                            {index === 0 && <Text style={styles.sectionTitle}>La tua esigenza</Text>}
                                            {index === 1 && <Text style={styles.sectionTitle}>La nostra soluzione</Text>}
                                            {index === 2 && <Text style={styles.sectionTitle}>Che cosa ottieni</Text>}
                                            <View style={styles.row}>
                                                <Text style={styles.moduleBody}>{block?.descrizione}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ))}
                    </View>
                    {mod?.dettagli && (
                        <View style={styles.heroSection}>
                            <Text style={styles.lightText}>{mod?.dettagli}</Text>
                        </View>
                    )}

                    {mod?.other_Modulo && mod?.other_Modulo.length > 0 && (
                        <View style={styles.heroSection}>
                            <Text style={styles.lightText}>
                                Scopri di più su
                                {mod?.other_Modulo.map((relatedModule, index) => (
                                    <Text key={index} style={{ display: 'inline' }}>
                                        {index !== mod?.other_Modulo.length - 1 && (
                                            <Text style={{ display: 'inline' }}>
                                                <Text style={styles.bold}> {relatedModule?.titolo}</Text>,
                                            </Text>
                                        )}
                                        {index === mod?.other_Modulo.length - 1 && (
                                            <Text style={{ display: 'inline' }}>
                                                <Text style={styles.bold}> {relatedModule?.titolo}</Text>
                                            </Text>
                                        )}
                                    </Text>
                                ))}
                                !
                            </Text>
                        </View>
                    )}
                    <View style={styles.footerNumber}>
                        <Text style={styles.textSubtle}>
                            &mdash; {index + 1} / {totalPages}
                        </Text>
                        <View style={styles.endRow}>
                            <Text style={styles.textSubtle}>artelnet.com/product/</Text>
                            <Link style={styles.footerLink} href={`https://www.artelnet.com/product/${mod?.Prodotto?.slug}/${mod?.slug}`}>
                                {mod?.Prodotto?.slug}/{mod?.slug}
                            </Link>
                        </View>
                    </View>
                </Page>
            ))}
            <Page size="A5" style={{ ...styles.darkPage, height: '100%', padding: '20px' }}>
                <View style={{ ...styles.body, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ ...styles.introText, color: 'rgb(255,255,255)', fontSize: 12, textAlign: 'center' }}>
                        <Text style={styles.bold}>Contattaci per una consulenza dedicata!</Text>
                    </Text>

                    <Text style={{ ...styles.introText, color: 'rgb(255,255,255)', textAlign: 'center' }}>
                        Formuleremo insieme il preventivo migliore per l&apos;installazione e la personalizzazione dei nostri
                        prodotti/servizi, per adattarci al meglio alle esigenze operative della tua azienda.
                    </Text>
                </View>
                <View
                    style={{
                        // ...styles.body,
                        padding: 10,
                        border: '5px solid white',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '70%',
                        margin: '0 auto'
                        // height: 30
                    }}
                >
                    <Link
                        href="mailto:commerciale@artelnet.com"
                        style={{
                            textAlign: 'center',
                            // ...styles.introText,
                            // height: 50,
                            // width: 'auto',
                            fontFamily: 'IBM Plex Sans',
                            textDecoration: 'none',
                            color: 'rgb(255,255,255)',
                            fontWeight: 600,
                            fontSize: 16

                            // margin: 0,
                            // padding: 0
                        }}
                    >
                        commerciale@artelnet.com
                    </Link>
                </View>
                <View
                    style={{
                        ...styles.body,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Text style={{ ...styles.introText, textAlign: 'center', color: 'rgb(255,255,255)' }}>
                        <Text style={styles.bold}>
                            Scopri di più sui prodotti e i servizi del mondo Artel.{` `}
                            <br />
                            Scansiona il codice QR con il tuo smartphone e visita il nostro sito!
                        </Text>
                    </Text>
                    <Image
                        style={{
                            // ...styles.logo,
                            width: 'auto',
                            height: 80,
                            objectFit: 'contain'
                        }}
                        src={`${process.env.PUBLIC_URL}/images/brochure/qr-code-artelnet.jpg`}
                    />
                    <Image
                        style={{
                            // ...styles.logo,
                            width: 'auto',
                            height: 15,
                            marginTop: 30,
                            objectFit: 'contain'
                        }}
                        src={`${process.env.PUBLIC_URL}/images/brand/artel-logotype.jpg`}
                    />
                </View>
            </Page>
        </Document>
    );
};

CustomBrochure.propTypes = {
    mods: PropTypes.array.isRequired,
    recipientName: PropTypes.string.isRequired,
    generationDate: PropTypes.string.isRequired
};

export default CustomBrochure;
