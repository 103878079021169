import { gql } from '@apollo/client';

const GET_HOME_COMPONENTS = gql`
    query {
        allSliders {
            slug
            titolo
            descrizione
            immagine
            link
            visibile
            filtro
            maxelementi
            CorpoSlider {
                titolo
                descrizione
                immagine
                link
                ordine
                ruolo
                nome
                Articolo {
                    ud_articolo
                    titolo
                    immagine
                    tipo
                    descrizione
                    pubblico
                    dt_pubblicazione
                    slug
                    CorpoArticolo {
                        ordine
                        titolo
                        descrizione
                    }
                }
                Modulo {
                    Id_Modulo
                    titolo
                    slug
                    descrizione
                    icona
                    logo
                    pubblico
                    etichetta
                    sottotitolo
                    CorpoModulo {
                        descrizione
                    }
                    Prodotto {
                        slug
                        nome
                    }
                }
                Prodotto {
                    nome
                    slug
                    immagine
                    stile
                    descrizione
                    sottotitolo
                    Icona_Prodotto_ud_iconaToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_iconalightToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_logotipoToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_logotipolightToIcona {
                        src
                        heightLg
                        heightSm
                    }
                }
                Cliente_CorpoSlider_ud_clienteToCliente {
                    TimeUpd
                    slug
                    stile
                    nome
                    titolo
                    descrizione
                    immagine
                    link
                    pubblico
                }
            }

            TimeUpd
        }
    }
`;
const GET_HOME_COMPONENT_BY_SLUG = gql`
    query sliderBySlug($slug: String!) {
        sliderBySlug(slug: $slug) {
            slug
            titolo
            descrizione
            immagine
            link
            visibile
            filtro
            maxelementi
            CorpoSlider {
                titolo
                descrizione
                immagine
                link
                ruolo
                nome
                ordine
                Articolo {
                    ud_articolo
                    titolo
                    immagine
                    tipo
                    descrizione
                    pubblico
                    dt_pubblicazione
                    slug
                    CorpoArticolo {
                        ordine
                        titolo
                        descrizione
                    }
                }
                Modulo {
                    Id_Modulo
                    titolo
                    slug
                    descrizione
                    icona
                    logo
                    pubblico
                    etichetta
                    sottotitolo
                    CorpoModulo {
                        descrizione
                    }
                    Prodotto {
                        slug
                        nome
                    }
                }
                Prodotto {
                    nome
                    slug
                    immagine
                    stile
                    descrizione
                    sottotitolo
                    Icona_Prodotto_ud_iconaToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_iconalightToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_logotipoToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_logotipolightToIcona {
                        src
                        heightLg
                        heightSm
                    }
                }
                Cliente_CorpoSlider_ud_clienteToCliente {
                    TimeUpd
                    slug
                    stile
                    nome
                    titolo
                    descrizione
                    immagine
                    link
                    pubblico
                }
            }
        }
    }
`;

const GET_PROD_SLUGS = gql`
    query {
        allProdottiMeta {
            slug
            nome
        }
    }
`;
const GET_PRODUCTS = gql`
    query allProdotti {
        allProdotti {
            TimeUpd
            ud_prodotto
            descrizione
            nome
            sottotitolo
            slug
            pubblico
            stile
            immagine
            Feature {
                ud_feature
            }
            Modulo {
                titolo
                descrizione
                sottotitolo
                slug
            }
            Icona_Prodotto_ud_iconaToIcona {
                src
                heightLg
                heightSm
            }
            Icona_Prodotto_ud_iconalightToIcona {
                src
                heightLg
                heightSm
            }
            Icona_Prodotto_ud_logotipoToIcona {
                src
                heightLg
                heightSm
            }
            Icona_Prodotto_ud_logotipolightToIcona {
                src
                heightLg
                heightSm
            }
        }
    }
`;

const GET_PRODUCT_BY_SLUG = gql`
    query prodottoBySlug($slug: String!) {
        prodottoBySlug(slug: $slug) {
            TimeUpd
            ud_prodotto
            descrizione
            immagine
            stile
            Icona_Prodotto_ud_iconaToIcona {
                src
                heightLg
                heightSm
            }
            Icona_Prodotto_ud_iconalightToIcona {
                src
                heightLg
                heightSm
            }
            Icona_Prodotto_ud_logotipoToIcona {
                src
                heightLg
                heightSm
            }
            Icona_Prodotto_ud_logotipolightToIcona {
                src
                heightLg
                heightSm
            }
            nome
            sottotitolo
            slug
            # FEATURE
            Feature {
                nome
                TipoFeature {
                    tipo
                    ContenutoFeature {
                        didascalia
                        citazione
                        valore
                        valorenum
                        etichettahref
                        href
                        imgsrc
                    }
                }
                slug
            }
            Modulo {
                titolo
                slug
                dettagli
                descrizione
                primario
                icona
                pubblico
                etichetta
                sottotitolo
                Prodotto {
                    nome
                    slug
                }
            }
        }
    }
`;
const GET_MODULI_BY_SLUGS = gql`
    query moduliBySlugs($slugs: [String!]!) {
        moduliBySlugs(slugs: $slugs) {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
            Prodotto {
                nome
                sottotitolo
                stile
                slug
            }
        }
    }
`;

const GET_MODULE_BY_SLUG = gql`
    query moduloBySlug($slug: String!) {
        moduloBySlug(slug: $slug) {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
            CorpoModulo {
                titolo
                descrizione
            }
            other_Modulo {
                titolo
                slug
            }
            Prodotto {
                nome
                sottotitolo
                stile
                slug
                Icona_Prodotto_ud_iconaToIcona {
                    src
                    heightLg
                    heightSm
                }
                Icona_Prodotto_ud_iconalightToIcona {
                    src
                    heightLg
                    heightSm
                }
                Icona_Prodotto_ud_logotipoToIcona {
                    src
                    heightLg
                    heightSm
                }
                Icona_Prodotto_ud_logotipolightToIcona {
                    src
                    heightLg
                    heightSm
                }
            }
        }
    }
`;
const GET_MODULE_SLUGS = gql`
    query allModuli {
        allModuli {
            TimeUpd
            titolo
            slug
        }
    }
`;
const GET_PUBLIC_MODULES = gql`
    query allModuliPub {
        allModuliPub {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
            Prodotto {
                nome
                slug
            }
            CorpoModulo {
                titolo
                descrizione
            }
            other_Modulo {
                titolo
                slug
            }
        }
    }
`;
const GET_ROUTE = gql`
    query protectedRoute($id: String!) {
        protectedRoute(ud_percorso: $id) {
            ud_percorso
        }
    }
`;

const GET_PRODUCT_COUNTER_BY_SLUG = gql`
    query productCounterByProductSlug($slug: String!) {
        productCounterByProductSlug(slug: $slug) {
            ContenutoFeature {
                didascalia
                valore
                valorenum
                ud_contenutofeature
            }
        }
    }
`;

const GET_MODULES = gql`
    query allModuli {
        allModuli {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
            Prodotto {
                nome
                slug
            }
            CorpoModulo {
                titolo
                descrizione
            }
            other_Modulo {
                titolo
                slug
            }
        }
    }
`;

const GET_FILTERED_MODULES = gql`
    query filteredModuli($product: String!, $primario: Boolean) {
        filteredModuli(product: $product, primario: $primario) {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
            Prodotto {
                nome
                slug
            }
            CorpoModulo {
                titolo
                descrizione
            }
            other_Modulo {
                titolo
                slug
            }
        }
    }
`;

const REORDER_MODULES = gql`
    mutation reorderModules($prodotto: String!, $moduli: [ModuloOrdineInput!]!, $utente: String!) {
        reorderModules(prodotto: $prodotto, moduli: $moduli, utente: $utente) {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
        }
    }
`;

const UPDATE_COUNTER = gql`
    mutation editProductCounterByProductSlug($counters: [CounterInput!]!) {
        editProductCounterByProductSlug(slug: "cloud", counters: $counters) {
            ContenutoFeature {
                didascalia
                valore
                valorenum
                ud_contenutofeature
            }
        }
    }
`;
const GET_NEXT_MODULE = gql`
    query nextModulo($slug: String!) {
        nextModulo(slug: $slug) {
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            pubblico
            etichetta
            sottotitolo
        }
    }
`;
const GET_PREV_MODULE = gql`
    query prevModulo($slug: String!) {
        prevModulo(slug: $slug) {
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            pubblico
            etichetta
            sottotitolo
        }
    }
`;

const GET_ALL_MEDIA = gql`
    query allMedia {
        allMedia {
            TimeUpd
            ud_media
            nome
            descrizione
            src
            pubblico
        }
    }
`;

const GET_USERS = gql`
    query allUtenti {
        allUtenti {
            TimeUpd
            ud_utente
            nome
            cognome
            email
            Articolo {
                titolo
                descrizione
                pubblico
            }
            Prodotto {
                nome
                slug
            }
            Modulo {
                titolo
                slug
            }
            Ruolo {
                nome
                livello
            }
        }
    }
`;
const GET_NEWS = gql`
    query allArticoli {
        allArticoli {
            TimeUpd
            ud_articolo
            titolo
            immagine
            tipo
            descrizione
            pubblico
            dt_pubblicazione
            slug
            CorpoArticolo {
                ordine
                titolo
                descrizione
            }
        }
    }
`;
const GET_FRONTEND_NEWS = gql`
    query allArticoliPub {
        allArticoliPub {
            TimeUpd
            ud_articolo
            titolo
            immagine
            tipo
            pubblico
            descrizione
            dt_pubblicazione
            slug
            CorpoArticolo {
                ordine
                titolo
                descrizione
            }
        }
    }
`;
const GET_ARTICLE_BY_SLUG = gql`
    query articoloBySlug($slug: String!) {
        articoloBySlug(slug: $slug) {
            TimeUpd
            ud_articolo
            titolo
            href
            immagine
            tipo
            descrizione
            archiviato
            pubblico
            dt_pubblicazione
            slug
            CorpoArticolo {
                ordine
                titolo
                descrizione
            }
        }
    }
`;

const UPDATE_HOME_COMPONENT = gql`
    mutation updateSlider(
        $utente: String!
        $link: String
        $immagine: String
        $slug: String!
        $descrizione: String
        $titolo: String
        $visibile: Boolean
        $filtro: String
        $contenuto: [CorpoSliderInput!]!
    ) {
        updateSlider(
            ud_utente: $utente
            link: $link
            immagine: $immagine
            slug: $slug
            descrizione: $descrizione
            titolo: $titolo
            visibile: $visibile
            filtro: $filtro
            contenuto: $contenuto
        ) {
            slug
            titolo
            descrizione
            immagine
            link
            visibile
            filtro
            Utente {
                TimeUpd
                ud_utente
                nome
                cognome
                email
            }
            CorpoSlider {
                titolo
                descrizione
                immagine
                link
                ordine
                titolo
                nome
                ruolo
                descrizione
                immagine
                ordine
                Articolo {
                    ud_articolo
                    titolo
                    immagine
                    tipo
                    descrizione
                    pubblico
                    dt_pubblicazione
                    slug
                    CorpoArticolo {
                        ordine
                        titolo
                        descrizione
                    }
                }
                Modulo {
                    Id_Modulo
                    titolo
                    slug
                    descrizione
                    icona
                    logo
                    pubblico
                    etichetta
                    sottotitolo
                    CorpoModulo {
                        descrizione
                    }
                    Prodotto {
                        slug
                        nome
                    }
                }
                Prodotto {
                    nome
                    slug
                    immagine
                    stile
                    descrizione
                    sottotitolo
                    Icona_Prodotto_ud_iconaToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_iconalightToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_logotipoToIcona {
                        src
                        heightLg
                        heightSm
                    }
                    Icona_Prodotto_ud_logotipolightToIcona {
                        src
                        heightLg
                        heightSm
                    }
                }
                Cliente_CorpoSlider_ud_clienteToCliente {
                    TimeUpd
                    slug
                    stile
                    nome
                    titolo
                    descrizione
                    immagine
                    link
                    pubblico
                }
            }

            TimeUpd
        }
    }
`;

const UPDATE_MODULE = gql`
    mutation updateModulo(
        $slug: String!
        # $autore: String
        $descrizione: String
        $sottotitolo: String
        $icona: String
        $titolo: String
        $pubblico: Boolean
        $primario: Boolean
        $dettagli: String
        $contenuto: [ParagrafoInput]
        $modulirelativi: [ModuloInput]
        $utente: String!
        $slug_prodotto: String
    ) {
        updateModulo(
            slug: $slug
            titolo: $titolo
            sottotitolo: $sottotitolo
            descrizione: $descrizione
            dettagli: $dettagli
            pubblico: $pubblico
            primario: $primario
            ud_utente: $utente
            icona: $icona
            contenuto: $contenuto
            modulirelativi: $modulirelativi
            slug_prodotto: $slug_prodotto
        ) {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
            CorpoModulo {
                titolo
                descrizione
            }
            other_Modulo {
                titolo
                slug
            }
        }
    }
`;

const UPDATE_ARTICLE = gql`
    mutation updateArticolo(
        $slug: String!
        $descrizione: String
        $immagine: String
        $titolo: String
        $pubblico: Boolean
        $archiviato: Boolean
        $contenuto: [ParagrafoInput]
        $utente: String
    ) {
        updateArticolo(
            slug: $slug
            titolo: $titolo
            descrizione: $descrizione
            pubblico: $pubblico
            archiviato: $archiviato
            ud_utente: $utente
            immagine: $immagine
            contenuto: $contenuto
        ) {
            TimeUpd
            ud_articolo
            titolo
            href
            immagine
            tipo
            descrizione
            archiviato
            pubblico
            dt_pubblicazione
            slug
            CorpoArticolo {
                ordine
                titolo
                descrizione
            }
        }
    }
`;
const DELETE_MODULE = gql`
    mutation deleteModulo($slug: String!, $utente: String!) {
        deleteModulo(slug: $slug, ud_utente: $utente) {
            titolo
            slug
        }
    }
`;
const DELETE_ARTICLE = gql`
    mutation deleteArticolo($slug: String!, $utente: String!) {
        deleteArticolo(slug: $slug, ud_utente: $utente) {
            titolo
            slug
        }
    }
`;

const CREATE_ARTICLE = gql`
    mutation createArticolo(
        $titolo: String!
        $descrizione: String!
        $pubblico: Boolean
        $archiviato: Boolean
        $utente: String
        $dt_pubblicazione: DateTime
        $contenuto: [ParagrafoInput]
        $immagine: String
    ) {
        createArticolo(
            titolo: $titolo
            descrizione: $descrizione
            pubblico: $pubblico
            archiviato: $archiviato
            ud_utente: $utente
            dt_pubblicazione: $dt_pubblicazione
            contenuto: $contenuto
            immagine: $immagine
        ) {
            TimeUpd
            ud_articolo
            titolo
            href
            immagine
            tipo
            descrizione
            archiviato
            pubblico
            dt_pubblicazione
            slug
            CorpoArticolo {
                ordine
                titolo
                descrizione
            }
        }
    }
`;
const CREATE_MODULE = gql`
    mutation createModulo(
        $slug: String!
        $contenuto: [ParagrafoInput]
        $modulirelativi: [ModuloInput]
        # $autore: String
        $descrizione: String
        $sottotitolo: String
        $slug_prodotto: String!
        $icona: String
        $titolo: String!
        $pubblico: Boolean
        $primario: Boolean
        $dettagli: String
        $utente: String
    ) {
        createModulo(
            slug: $slug
            titolo: $titolo
            sottotitolo: $sottotitolo
            descrizione: $descrizione
            slug_prodotto: $slug_prodotto
            dettagli: $dettagli
            pubblico: $pubblico
            primario: $primario
            ud_utente: $utente
            contenuto: $contenuto
            icona: $icona
            modulirelativi: $modulirelativi
        ) {
            TimeUpd
            titolo
            slug
            dettagli
            descrizione
            primario
            icona
            logo
            raster
            pubblico
            etichetta
            sottotitolo
            CorpoModulo {
                titolo
                descrizione
            }
            other_Modulo {
                titolo
                slug
            }
        }
    }
`;

const GET_CONTACTS = gql`
    query allContatti {
        allContatti {
            TimeIns
            ud_contatto
            nome
            ragionesociale
            ruolo
            email
            messaggio
            privacy
            ContattoTopic {
                argomento
                tipo
                ud_contatto
            }
        }
    }
`;

const CREATE_CONTACT = gql`
    mutation createContatto(
        $nome: String!
        $email: String!
        $ruolo: String
        $ragionesociale: String
        $messaggio: String
        $privacy: Boolean!
        $argomenti: [ContattoTopicInput]
    ) {
        createContatto(
            nome: $nome
            email: $email
            ruolo: $ruolo
            ragionesociale: $ragionesociale
            messaggio: $messaggio
            privacy: $privacy
            argomenti: $argomenti
        ) {
            nome
            email
            ragionesociale
            ruolo
            messaggio
            privacy
            ContattoTopic {
                argomento
                tipo
            }
        }
    }
`;

const GET_CONTACT_BY_ID = gql`
    query contattoById($ud_contatto: String!) {
        contattoById(ud_contatto: $ud_contatto) {
            TimeIns
            ud_contatto
            nome
            ragionesociale
            ruolo
            email
            messaggio
            privacy
            ContattoTopic {
                argomento
                tipo
                ud_contatto
            }
        }
    }
`;
export {
    GET_USERS,
    GET_PRODUCTS,
    GET_PROD_SLUGS,
    GET_PRODUCT_BY_SLUG,
    GET_MODULES,
    GET_PUBLIC_MODULES,
    GET_MODULI_BY_SLUGS,
    GET_MODULE_BY_SLUG,
    GET_MODULE_SLUGS,
    REORDER_MODULES,
    GET_FILTERED_MODULES,
    GET_NEXT_MODULE,
    GET_PREV_MODULE,
    CREATE_MODULE,
    UPDATE_MODULE,
    DELETE_MODULE,
    GET_NEWS,
    GET_FRONTEND_NEWS,
    CREATE_ARTICLE,
    GET_ARTICLE_BY_SLUG,
    UPDATE_ARTICLE,
    DELETE_ARTICLE,
    GET_CONTACTS,
    GET_CONTACT_BY_ID,
    CREATE_CONTACT,
    GET_HOME_COMPONENTS,
    GET_HOME_COMPONENT_BY_SLUG,
    UPDATE_HOME_COMPONENT,
    GET_ALL_MEDIA,
    GET_ROUTE,
    GET_PRODUCT_COUNTER_BY_SLUG,
    UPDATE_COUNTER
};
