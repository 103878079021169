import { lazy } from 'react';

// project imports
import ContainerLayout from 'layout/ContainerLayout';
import FrontendLayout from 'layout/FrontendLayout';
import NotFoundLayout from 'layout/NotFoundLayout';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthLayout from 'layout/AuthLayout';
// import NotFound from 'views/not-found';
// import NewsArticle from 'views/frontend/news-article';
// import News from 'views/frontend/news';
// import OurDna from 'views/frontend/our-dna';
// import PrivacyPolicy from 'views/frontend/privacy';
// import Product from 'views/frontend/product';
// ? FRONTEND
const Home = Loadable(lazy(() => import('views/frontend/home')));
const Reserved = Loadable(lazy(() => import('views/frontend/reserved')));
const Product = Loadable(lazy(() => import('views/frontend/product')));
const Module = Loadable(lazy(() => import('views/frontend/module')));
const CaseHistory = Loadable(lazy(() => import('views/frontend/case-history')));
const CaseModule = Loadable(lazy(() => import('views/frontend/case-module')));
const News = Loadable(lazy(() => import('views/frontend/news')));
const NewsArticle = Loadable(lazy(() => import('views/frontend/news-article')));
const OurDna = Loadable(lazy(() => import('views/frontend/our-dna')));
const PrivacyPolicy = Loadable(lazy(() => import('views/frontend/privacy')));

// ? BACKEND
// * Dashboard
const AdminDashboard = Loadable(lazy(() => import('views/backend/dashboard')));
// * Articoli
const Articoli = Loadable(lazy(() => import('views/backend/article')));
const NewArticle = Loadable(lazy(() => import('views/backend/article/new')));
const ArticoloEdit = Loadable(lazy(() => import('views/backend/article/edit')));

// * Moduli
const Moduli = Loadable(lazy(() => import('views/backend/module')));
const NuovoModulo = Loadable(lazy(() => import('views/backend/module/new')));
const ModuloEdit = Loadable(lazy(() => import('views/backend/module/edit')));
// * Contatti
const Contatti = Loadable(lazy(() => import('views/backend/contact')));
// * Prodotti
const Prodotti = Loadable(lazy(() => import('views/backend/product')));
const ProdottoEdit = Loadable(lazy(() => import('views/backend/product/edit')));
// * HOME
const NewsCarouselEdit = Loadable(lazy(() => import('views/backend/home/news-carousel')));
const ClientReviewsEdit = Loadable(lazy(() => import('views/backend/home/client-reviews')));
const ClientsEdit = Loadable(lazy(() => import('views/backend/home/client')));
const ProductAccordionEdit = Loadable(lazy(() => import('views/backend/home/product-accordion')));
// ? 404
const NotFound = Loadable(lazy(() => import('views/not-found')));
// ? AUTHENTICATION ROUTING
const Login = Loadable(lazy(() => import('views/auth/authentication3/Login3')));
// const Register = Loadable(lazy(() => import('views/auth/authentication3/Register3')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const FrontendRoutes = {
    path: '/',
    element: <ContainerLayout />,
    children: [
        // ? FRONTEND
        {
            path: '/',
            element: <FrontendLayout />,
            children: [
                // ** // Home
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: '/our-dna',
                    element: <OurDna />
                },
                {
                    path: '/privacy',
                    element: <PrivacyPolicy />
                },
                {
                    path: '/product/:id',
                    element: <Product />
                },
                {
                    path: '/product/:id/:moduleId',
                    element: <Module />
                },
                {
                    path: '/news',
                    element: <News />
                },
                {
                    path: '/news/:newsId',
                    element: <NewsArticle />
                },
                {
                    path: '/case-history',
                    element: <CaseHistory />
                },
                {
                    path: '/case-history/:catId',
                    element: <CaseModule />
                }

                // {
                //     path: '/reserved',
                //     element: <Reserved />
                // }

                // // ** // Page
                // {
                //     path: '/page',
                //     element: <Page />
                // },
            ]
        },

        // ? ADMIN
        {
            path: '/admin/:adminid',
            element: <MainLayout />,
            children: [
                {
                    path: '',
                    element: <AdminDashboard />
                    // element: <ArticleList />
                },
                {
                    path: 'dashboard',
                    element: <AdminDashboard />
                },
                {
                    path: 'home/articoli',
                    element: <NewsCarouselEdit />
                },
                {
                    path: 'home/dicono-di-noi',
                    element: <ClientReviewsEdit />
                },
                {
                    path: 'home/prodotti',
                    element: <ProductAccordionEdit />
                },
                {
                    path: 'home/clienti',
                    element: <ClientsEdit />
                },
                {
                    path: 'articoli',
                    element: <Articoli />
                },
                {
                    path: 'articoli/:articleId',
                    element: <ArticoloEdit />
                },

                {
                    path: 'new/modulo',
                    element: <NuovoModulo />
                },
                {
                    path: 'new/articolo',
                    element: <NewArticle />
                },
                {
                    path: 'moduli',
                    element: <Moduli />
                },
                {
                    path: 'moduli/:moduleId',
                    element: <ModuloEdit />
                },
                {
                    path: 'prodotti',
                    element: <Prodotti />
                },
                {
                    path: 'prodotti/:productId',
                    element: <ProdottoEdit />
                },
                {
                    path: 'contatti',
                    element: <Contatti />
                }
            ]
        },
        // ? RESERVED
        {
            path: '/',
            element: <NotFoundLayout />,
            children: [
                {
                    path: 'reserved/',
                    element: <Reserved />
                    // element: <ArticleList />
                }
            ]
        }, // ? AUTH
        {
            path: '/',
            element: <AuthLayout />,
            children: [
                {
                    path: 'admin/:adminid/login',
                    element: <Login />
                    // element: <ArticleList />
                }
            ]
        },
        // // * NOT FOUND
        {
            path: '*',
            element: <FrontendLayout />,
            children: [
                {
                    path: '*',
                    element: <NotFound />
                }
            ]
        }
    ]
};

export default FrontendRoutes;
