export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        // Link: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.colors?.textDark,
        //             textDecoration: 'none',
        //             '&:hover': {
        //                 textDecoration: 'none',
        //                 color: theme.colors?.textDark
        //             },
        //             '&:visited': {
        //                 color: theme.colors?.textDark
        //             }
        //         }
        //     }
        // },

        MuiTypography: {
            styleOverrides: {
                root: {
                    '& a': {
                        color: 'inherit',
                        textDecoration: 'underline',
                        '&:hover': {
                            textDecoration: 'none',
                            // color: theme.colors?.primary200,
                            opacity: 0.5
                        },
                        '&:visited': {
                            color: 'inherit'
                        }
                    },
                    '& strong': {
                        fontWeight: 600,
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTabScrollButton: {
            styleOverrides: {
                root: {
                    // scroll button component
                    // '& .MuiTabs-indicator': {
                    background: theme.colors?.primaryMain,
                    opacity: 1,
                    color: bgColor
                    // }
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    borderRadius: '0',
                    textTransform: 'none',
                    fontSize: '0.875rem',
                    fontFamily: `'IBM Plex Sans Condensed', sans-serif`
                }
            }
        },

        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `0`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    // fontFamily: `'IBM Plex Sans Condensed', sans-serif`,
                    // color: theme.darkTextPrimary,
                    // opacity: 0.35,
                    color: theme.darkTextPrimary,
                    borderLeft: `4px solid ${theme.colors?.grey300}`,
                    opacity: 0.4,

                    // // paddingBottom: '10px',
                    '&.Mui-selected': {
                        '.MuiListItemText-root .MuiTypography-root': {
                            fontWeight: 500
                        },
                        borderLeft: `4px solid ${theme.colors?.primaryMain}`,
                        opacity: 1,
                        color: theme.primaryMain,
                        background: 'none',
                        '&:hover': {
                            color: theme.grey800
                        }
                        // '& .MuiListItemIcon-root': {
                        //     color: theme.menuSelected
                        // }
                    },
                    '&:hover': {
                        opacity: 1,
                        backgroundColor: 'none',
                        background: 'none',
                        color: theme.grey800,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    // opacity: 0.4,
                    fontWeight: 400
                    // borderLeft: `4px solid ${theme.colors?.grey300}`,
                    // // paddingBottom: '10px',
                    // '&.Mui-selected': {
                    //     '.MuiListItemText-root .MuiTypography-root': {
                    //         fontWeight: 500
                    //     },
                    //     borderLeft: `4px solid ${theme.colors?.primaryMain}`,
                    //     opacity: 1,
                    //     color: theme.primaryMain,
                    //     background: 'none',
                    //     '&:hover': {
                    //         color: theme.grey800
                    //     }
                    //     // '& .MuiListItemIcon-root': {
                    //     //     color: theme.menuSelected
                    //     // }
                    // },
                    // '&:hover': {
                    //     opacity: 1,
                    //     // backgroundColor: 'none',
                    //     background: 'none',
                    //     color: theme.grey800
                    //     // '& .MuiListItemIcon-root': {
                    //     //     color: theme.menuSelected
                    //     // }
                    // }
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    '>.MuiTypography-root': {
                        fontFamily: `'IBM Plex Sans Condensed', sans-serif`,
                        fontWeight: 400
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },

        // MuiInputBase: {
        //     styleOverrides: {
        //         root: {
        //             '&.MuiInput-underline.Mui-focused:after': {
        //                 borderBottom: `2px solid ${theme.primaryMain}`
        //             },
        //             '&.MuiInput-underline:not(.Mui-focused):not(.Mui-error)': {
        //                 borderBottom: `2px solid ${theme?.colors?.primary200} !important`
        //             },

        //             marginBottom: '0',
        //             // marginTop: '2rem',
        //             paddingTop: '0.5rem',
        //             fontSize: '2rem',
        //             fontWeight: 300,
        //             borderRadius: '0',
        //             '&.MuiInputBase-multiline': {
        //                 // padding: 1
        //             },
        //             '&.Mui-focused': {
        //                 background: 'none',
        //                 transition: 'background 0.3s ease-in-out'
        //             }
        //         },
        //         input: {
        //             margin: '.5rem 0 0 0',
        //             color: theme.primaryMain,
        //             '&::placeholder': {
        //                 color: theme.darkTextSecondary,
        //                 fontSize: '2rem'
        //             }
        //         }
        //     }
        // },
        // MuiInputLabel: {
        //     styleOverrides: {
        //         root: {
        //             marginTop: '1rem',
        //             color: theme.darkTextSecondary,
        //             fontSize: '2rem',
        //             transform: 'translate(0, 1.5rem)',
        //             fontWeight: 300,
        //             '&.Mui-focused': {
        //                 // opacity: 1,
        //                 color: theme.primaryMain
        //             }
        //         },
        //         shrink: {
        //             fontSize: '1.25rem',
        //             fontWeight: 600,
        //             transform: 'translate(0, -0.5rem)'
        //         },
        //         asterisk: {
        //             color: theme.colors?.error
        //         }
        //     }
        // },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        }
    };
}
