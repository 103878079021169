// material-ui
import { ArrowLeft, ArrowRight, Checkmark, Error, MailAll, Popup, Time } from '@carbon/icons-react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    FormControlLabel,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useLocation } from 'react-router';
import ModuleIcon from 'ui-component/icons/modules/ModuleIcon';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import CustomBrochure from 'ui-component/pdf/CustomBrochure';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PUBLIC_MODULES, CREATE_CONTACT } from 'utils/queries';
import ReactGA from 'react-ga4';

const FormContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    // flexDirection: 'row',
    height: '100%',
    // alignItems: 'stretch',
    width: '100%'
    // alignItems: 'center',
    // justifyContent: 'space-between'
    // maxWidth: '1280px'
    // margin: '1em'
}));

const ContactForm = ({ isPopup }) => {
    const [mcUrl, setMcUrl] = useState(null);
    const [cookies, setCookie] = useCookies();

    const mcURI = 'https://artelnet.us21.list-manage.com/subscribe/post?u=9b0cc066f219d168f64e570b4&id=2372bb537b';
    useEffect(() => {
        setMcUrl(mcURI);
    }, []);

    if (mcUrl === null) return <CircularProgress size={24} />;

    return (
        <MailchimpSubscribe
            url={mcUrl}
            render={({ subscribe, status, message }) => (
                <CustomContactForm
                    status={status}
                    message={message}
                    popup={isPopup}
                    onValidated={(formData) => {
                        subscribe(formData);
                    }}
                />
            )}
        />
    );
};

const CustomContactForm = ({ status, message, onValidated, popup }) => {
    const theme = useTheme();
    const route = useLocation();
    // removeHash();
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const {
        handleSubmit,
        reset,
        watch,
        register,
        control,
        formState: { errors }
    } = useForm();
    const methods = useFormContext();
    const [isHoveringElement, setIsHoveringElement] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isFormSent, setIsFormSent] = useState(false);
    const [createFn, mutateCtx] = useMutation(CREATE_CONTACT);
    const { loading, error, data } = useQuery(GET_PUBLIC_MODULES);
    const [moduleList, setModuleList] = useState([]);
    const generateFileName = () => {
        const today = new Date();
        // format date
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        const hh = String(today.getHours()).padStart(2, '0');
        const min = String(today.getMinutes()).padStart(2, '0');
        const ss = String(today.getSeconds()).padStart(2, '0');
        const fileName = `Artel_Brochure_${dd}-${mm}-${yyyy}_${hh}-${min}-${ss}.pdf`;
        return fileName;
    };
    const [fullName, setFullName] = useState(null);
    const [formData, setFormData] = useState(null);
    const [emailAddr, setEmailAddr] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companyRole, setCompanyRole] = useState(null);
    const [formTopics, setFormTopics] = useState([]);
    const [isErrored, setIsErrored] = useState(false);
    const [handwrittenTopics, setHandwrittenTopics] = useState([]);
    const [messageBody, setMessageBody] = useState(null);
    const [policyConsent, setPolicyConsent] = useState(false);
    const [cookies, setCookie] = useCookies(['modules']);
    const [pinnedModules, setPinnedModules] = useState([]);
    const [modules, setModules] = useState([]);
    const [reqStatus, setReqStatus] = useState(0);
    const helperTextProps = {
        sx: {
            fontSize: '0.75rem',
            // color: theme.palette.error.main,
            // mb: '1.5rem',
            mt: 0
        }
    };
    const InputProps = {
        // mb: '1.5rem'
        '.MuiInput-underline.Mui-focused:after': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        },
        '.MuiInput-underline:not(.Mui-focused):not(.Mui-error)': {
            borderBottom: `2px solid ${theme.palette.primary[200]} !important`
        },
        '.MuiInputBase-root': {
            marginBottom: '0',
            // marginTop: '2rem',
            paddingTop: '0.5rem',
            fontSize: matchUpMd ? '2rem' : '1.25rem',
            fontWeight: 300,
            borderRadius: '0',
            '&.MuiInputBase-multiline': {
                // padding: 1
            },
            '&.Mui-focused': {
                background: 'none',
                transition: 'background 0.3s ease-in-out'
            }
        },
        '& input, textarea': {
            margin: '.5rem 0 0 0',
            color: theme.palette.primary.main,
            '&::placeholder': {
                color: theme.palette.text.secondary,
                fontSize: matchUpMd ? '2rem' : '1.25rem'
            }
        },
        '.MuiInputLabel-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            marginTop: '1rem',
            color: theme.palette.text.secondary,
            fontSize: matchUpMd ? '2rem' : '1.25rem',
            transform: 'translate(0, 1.5rem)',
            fontWeight: 300,
            '&.Mui-focused': {
                // opacity: 1,
                color: theme.palette.primary.main
            }
        },
        '.MuiInputLabel-shrink': {
            fontSize: matchUpMd ? '1.25rem' : '0.875rem',
            fontWeight: 600,
            transform: 'translate(0, -0.5rem)'
        }
    };
    // configureAnchors({
    //     // offset: -200,
    //     scrollDuration: 900
    //     // keepLastAnchorHash: false
    // });
    const iubendaCookie = cookies['_iub_cs-96972586'];

    const generateDataForExpress = (formData) => {
        try {
            // console.log('formData: ', formData);
            let status = 200;
            const data = {
                fullName: `${formData.fname} ${formData.lname}`,
                emailAddr: formData.email,
                companyName: formData.companyName,
                companyRole: formData.companyRole,
                formTopics: formData.formTopics,
                messageBody: formData.messageBody,
                policyConsent: formData.policyConsent
            };

            // console.log('DATA', data);

            if (data) {
                console.warn(`make request to server with data: ${data}`);
                console.log('topics: ', data?.formTopics);
                axios({
                    method: 'POST',
                    url: `${process.env.PUBLIC_URL}/api/sendcontact`,
                    data: {
                        name: data.fullName,
                        email: data.emailAddr,
                        message: data.messageBody,
                        userRole: data.companyRole,
                        companyName: data.companyName,
                        modules: data.formTopics,
                        attachmentName: generateFileName(),
                        fileDate: new Date().toLocaleDateString('it-IT', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        })
                    }
                })
                    .then((response) => {
                        console.log('response', response);
                        status = response.status;
                        if (response.data === 'OK' && response.status === 200) {
                            console.log('Richiesta ricevuta correttamente dal server.', response.data, response.status);
                            return status;
                        }
                        console.warn('Richiesta non inviata. Errore dal server.');
                        return status;
                    })
                    .catch((error) => {
                        console.log(error.response);

                        if (error.response) {
                            if (error.response.status === 400 || error.response.status === 500) {
                                console.warn('Richiesta non inviata. Errore dal server.');
                                return status;
                            }
                            if (error.response.status === 404) {
                                console.warn('Richiesta non inviata. Server non trovato.');
                                return status;
                            }

                            console.warn('Richiesta non inviata. Errore sconosciuto.');
                            return status;
                        }
                        if (error.request) {
                            console.warn('Richiesta non inviata. Errore di connessione.');
                            return status;
                        }

                        console.warn('Richiesta non inviata. Errore sconosciuto.');
                        status = 500;
                        return status;
                    });
            } else {
                console.log('No data to send.');
                status = 500;
            }
            return status;
        } catch (error) {
            console.log(error);
        }

        return status;
    };

    const maxSteps = 3;
    const [allTrue, setAllTrue] = useState(false);
    // watch();
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onStepChangeForward = () => {
        if (activeStep === 0 && !errors.fullName && !errors.emailAddr) {
            handleNext();
        } else if (activeStep === 1 && !errors.companyName && !errors.companyRole) {
            handleNext();
        } else if (activeStep === 2 && !errors.messageBody && !errors.policyConsent && !errors.formTopics) {
            // setIsFormSent(true);
        }
    };

    useEffect(() => {
        // console.log(instance);
        if (iubendaCookie && iubendaCookie?.purposes) {
            const allAccepted = Object.values(iubendaCookie?.purposes)?.every((purpose) => purpose === true);
            setAllTrue(allAccepted);
        }
        if (data && data?.allModuliPub && data?.allModuliPub?.length > 0) {
            setModuleList(data.allModuliPub);
        }
        if (cookies.modules && cookies.modules.length > 0) {
            setPinnedModules(cookies.modules);
        } else {
            setPinnedModules([]);
        }
    }, [cookies.modules, data, iubendaCookie]);

    const gatherModulesForBrochure = async () => {
        // console.log('pinned modules: ', pinnedModules);

        if (pinnedModules.length > 0) {
            const filteredModules = await moduleList.filter((mod) => pinnedModules.find((pinned) => pinned.slug === mod.slug));
            // group modules by product slug
            setModules(filteredModules);
            // console.log('filtered modules: ', filteredModules);
        }
        return modules;
    };

    return (
        <Box
            id="lets-talk"
            sx={{
                zIndex: 1,
                width: '100%',
                my: '2rem'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    // background: theme.palette.background.paper,
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    zIndex: 100,
                    mb: matchUpMd ? '1rem' : '0',
                    [theme.breakpoints.up('md')]: {
                        maxWidth: '1366px'
                    },
                    [theme.breakpoints.up('lg')]: {
                        maxWidth: '1366px'
                    },
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: '1366px'
                    },
                    [theme.breakpoints.down('md')]: {
                        maxWidth: '100vw'
                    }
                    // boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
                }}
            >
                <Box
                    sx={{
                        // pb: '2rem',
                        // mb: '-1rem',
                        // boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: '2rem',
                        mx: '2rem'
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            mb: matchUpMd ? '3rem' : '1rem',
                            textAlign: 'center',
                            // fontSize: matchUpSm ? '2rem' : '1.5rem',
                            fontWeight: 500,
                            color: theme.palette.primary.main
                        }}
                    >
                        Parliamone.
                    </Typography>

                    <Typography sx={{ textAlign: 'center', mx: matchUpMd ? 0 : '2rem' }} variant="body">
                        Inizia una nuova conversazione col nostro ufficio commerciale per ottenere maggiori informazioni sulle opportunità
                        di crescita per la tua impresa.
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    zIndex: 1,
                    width: '100%',
                    // minHeight: '500px',
                    height: '100%',
                    background: popup
                        ? 'none'
                        : `linear-gradient(180deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.light} 50%, ${theme.palette.background.paper} 100%)`,
                    py: '2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        my: matchUpMd ? '2rem' : 0,
                        width: '100%',
                        height: '100%',
                        mx: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        [theme.breakpoints.up('md')]: {
                            maxWidth: '1366px'
                        },
                        [theme.breakpoints.up('lg')]: {
                            maxWidth: '1366px'
                        },
                        [theme.breakpoints.up('xl')]: {
                            maxWidth: '1366px'
                        },
                        [theme.breakpoints.down('md')]: {
                            maxWidth: '100vw'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            padding: matchUpMd ? '2rem' : '0'
                            // '& .Mui-disabled': {
                            //     background: theme.palette.primary.light,
                            //     color: theme.palette.primary[200]
                            // }
                        }}
                    >
                        {!allTrue ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <Typography variant="body" sx={{ mb: 3, color: theme.palette.grey[700] }}>
                                    Per poter utilizzare le funzionalità di contatto, è necessario accettare i cookie di terze parti.
                                </Typography>
                                <Button
                                    endIcon={<Popup />}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => {
                                        const _iub = window._iub;
                                        const iubAPI = _iub.cs.api;
                                        if (iubAPI) {
                                            iubAPI.openPreferences();
                                        }
                                        // window.location.reload();
                                    }}
                                >
                                    Rivedi preferenze
                                </Button>
                            </Box>
                        ) : (
                            <FormProvider {...methods}>
                                <form
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                        // height: '500px'
                                    }}
                                    onSubmit={handleSubmit(async (data) => {
                                        if (data) {
                                            data.formTopics = [...pinnedModules, ...formTopics];

                                            if (
                                                data.formTopics.length > 0 &&
                                                data.messageBody &&
                                                data.fullName &&
                                                data.emailAddr &&
                                                data.companyName &&
                                                data.companyRole &&
                                                data.policyConsent
                                            ) {
                                                const parsedName =
                                                    data?.fullName?.split(' ')?.length > 1 ? data?.fullName?.split(' ') : [data?.fullName];
                                                const firstName = parsedName[0];
                                                const lastName = parsedName.slice(1).join(' ') || '';

                                                if (firstName && lastName !== undefined) {
                                                    gatherModulesForBrochure();
                                                    ReactGA.event({
                                                        category: 'Form inviato',
                                                        action: 'brochure-form',
                                                        label: 'Form inviato'
                                                    });
                                                    console.log(`✉️✅ Inviato evento: Compilazione form brochure`);
                                                    // if (gotModules && gotModules.length > 0) {
                                                    setCookie('modules', [], {
                                                        path: '/',
                                                        expires: new Date(Date.now() + 1000)
                                                    });
                                                    if (navigator.setAppBadge || navigator.clearAppBadge) {
                                                        // navigator.setAppBadge(
                                                        //     cookies.modules.length
                                                        // );
                                                        navigator.clearAppBadge();
                                                    }
                                                    onValidated({
                                                        EMAIL: data?.emailAddr,
                                                        FNAME: firstName,
                                                        LNAME: lastName
                                                    });
                                                    const formStatus = await generateDataForExpress({
                                                        fname: firstName,
                                                        lname: lastName,
                                                        email: data?.emailAddr,
                                                        companyName: data?.companyName,
                                                        companyRole: data?.companyRole,
                                                        messageBody: data?.messageBody,
                                                        formTopics: data?.formTopics,
                                                        policyConsent: data?.policyConsent
                                                    });

                                                    if (formStatus === 200) {
                                                        setFormData({
                                                            fname: firstName,
                                                            lname: lastName,
                                                            email: data?.emailAddr,
                                                            companyName: data?.companyName,
                                                            companyRole: data?.companyRole,
                                                            messageBody: data?.messageBody,
                                                            formTopics: data?.formTopics,
                                                            policyConsent: data?.policyConsent
                                                        });

                                                        const updated = await createFn({
                                                            variables: {
                                                                nome: data?.fullName,
                                                                email: data?.emailAddr,
                                                                ruolo: data?.companyRole,
                                                                ragionesociale: data?.companyName,
                                                                messaggio: data?.messageBody,
                                                                privacy: data?.policyConsent,
                                                                argomenti: data?.formTopics?.map((topic) => {
                                                                    if (topic?.freeTopic) {
                                                                        return {
                                                                            argomento: topic?.title,
                                                                            tipo: 'T'
                                                                        };
                                                                    }
                                                                    return {
                                                                        argomento: topic?.slug,
                                                                        tipo: 'M'
                                                                    };
                                                                })
                                                            }
                                                        });

                                                        if (updated) {
                                                            setIsFormSent(true);
                                                        }
                                                    } else {
                                                        setIsFormSent(false);
                                                        setIsErrored(true);
                                                    }
                                                }

                                                // setPinnedModules([]);
                                                // }
                                            } else {
                                                console.log('form not valid');
                                            }
                                        }
                                    })}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        {loading && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'space-between',
                                                    height: '100%',
                                                    width: '100%',
                                                    px: '2rem'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontWeight: 600,
                                                        color: theme.palette.primary.main,
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    <Time size={16} />
                                                    &nbsp;&nbsp;Caricamento...
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        my: '2rem'
                                                    }}
                                                >
                                                    <CircularProgress size={24} />
                                                </Box>
                                            </Box>
                                        )}
                                        {!isFormSent && !isErrored && (
                                            <FormContainer
                                                sx={{
                                                    flexDirection: matchUpMd ? 'row' : 'column',
                                                    justifyContent: matchUpMd ? 'space-between' : 'flex-start',
                                                    alignItems: matchUpMd ? 'center' : 'flex-start'
                                                }}
                                            >
                                                {matchUpMd && (
                                                    <Button
                                                        disableElevation
                                                        sx={{
                                                            width: '3rem',
                                                            height: '3rem',
                                                            maxHeight: '3rem',
                                                            maxWidth: '3rem',
                                                            minWidth: '3rem',
                                                            p: 0,
                                                            borderRadius: '50%'
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBack}
                                                        disabled={activeStep === 0}
                                                    >
                                                        <ArrowLeft size={24} />
                                                    </Button>
                                                )}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'flex-start',
                                                        width: '100%',
                                                        height: '100%',
                                                        px: '2rem'
                                                    }}
                                                >
                                                    <Typography sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                                                        &mdash; {activeStep + 1} / {maxSteps}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                    >
                                                        {activeStep === 0 && (
                                                            <>
                                                                <Controller
                                                                    name="fullName"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    value={fullName}
                                                                    rules={{
                                                                        required: 'È necessario inserire un nome',
                                                                        pattern: {
                                                                            value: /^[a-zA-Z\s]*$/,
                                                                            message: 'Inserisci un nome e cognome validi'
                                                                        },
                                                                        minLength: { value: 2, message: 'Minimo caratteri: 2' },
                                                                        maxLength: { value: 80, message: 'Massimo caratteri: 80' },
                                                                        validate: true
                                                                    }}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                        fieldState: { invalid, isTouched, isDirty, error }
                                                                    }) => (
                                                                        <TextField
                                                                            sx={InputProps}
                                                                            fullWidth
                                                                            FormHelperTextProps={helperTextProps}
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            error={!!error}
                                                                            helperText={error ? error.message : null}
                                                                            type="text"
                                                                            variant="standard"
                                                                            color="primary"
                                                                            label="Come ti chiami?"
                                                                            placeholder="Nome e cognome"
                                                                        />
                                                                    )}
                                                                />

                                                                <Controller
                                                                    name="emailAddr"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    value={emailAddr}
                                                                    rules={{
                                                                        required: 'È necessario inserire un indirizzo e-mail',
                                                                        pattern: {
                                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                            message: 'Indirizzo e-mail non valido'
                                                                        },

                                                                        validate: true
                                                                    }}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                        fieldState: { invalid, isTouched, isDirty, error }
                                                                    }) => (
                                                                        <TextField
                                                                            sx={InputProps}
                                                                            fullWidth
                                                                            FormHelperTextProps={helperTextProps}
                                                                            value={value}
                                                                            ref={emailAddr}
                                                                            onBlur={onBlur}
                                                                            onChange={onChange}
                                                                            error={!!error}
                                                                            helperText={error ? error.message : null}
                                                                            type="email"
                                                                            name={name}
                                                                            variant="standard"
                                                                            label="Inserisci un indirizzo mail dove essere contattato/a"
                                                                            placeholder="Indirizzo e-mail"
                                                                        />
                                                                    )}
                                                                />
                                                            </>
                                                        )}
                                                        {activeStep === 1 && (
                                                            <>
                                                                <Controller
                                                                    name="companyName"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    FormHelperTextProps={helperTextProps}
                                                                    value={companyName}
                                                                    rules={{
                                                                        required: 'È necessario inserire una ragione sociale',
                                                                        minLength: { value: 2, message: 'Minimo caratteri: 2' },
                                                                        maxLength: { value: 80, message: 'Massimo caratteri: 80' },
                                                                        validate: true
                                                                    }}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                        fieldState: { invalid, isTouched, isDirty, error }
                                                                    }) => (
                                                                        <TextField
                                                                            sx={InputProps}
                                                                            fullWidth
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            error={!!error}
                                                                            helperText={error ? error.message : null}
                                                                            type="text"
                                                                            label="Inserisci una ragione sociale"
                                                                            variant="standard"
                                                                            placeholder="Ragione sociale"
                                                                        />
                                                                    )}
                                                                />
                                                                <Controller
                                                                    name="companyRole"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    FormHelperTextProps={helperTextProps}
                                                                    value={companyRole}
                                                                    rules={{
                                                                        required: 'È necessario inserire un ruolo',
                                                                        minLength: { value: 2, message: 'Minimo caratteri: 2' },
                                                                        maxLength: { value: 80, message: 'Massimo caratteri: 80' },
                                                                        validate: true
                                                                    }}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                        fieldState: { invalid, isTouched, isDirty, error }
                                                                    }) => (
                                                                        <TextField
                                                                            sx={InputProps}
                                                                            fullWidth
                                                                            FormHelperTextProps={helperTextProps}
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            error={!!error}
                                                                            helperText={error ? error.message : null}
                                                                            type="text"
                                                                            label="Qual è il tuo ruolo all'interno dell'organizzazione aziendale?"
                                                                            variant="standard"
                                                                            placeholder="Ruolo aziendale"
                                                                        />
                                                                    )}
                                                                />
                                                            </>
                                                        )}
                                                        {activeStep === 2 && (
                                                            <>
                                                                <Controller
                                                                    name="formTopics"
                                                                    control={control}
                                                                    defaultValue={[]}
                                                                    FormHelperTextProps={helperTextProps}
                                                                    value={formTopics}
                                                                    rules={{
                                                                        required:
                                                                            pinnedModules?.length > 0
                                                                                ? false
                                                                                : 'È necessario inserire almeno un argomento',
                                                                        validate: true
                                                                    }}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                        fieldState: { invalid, isTouched, isDirty, error }
                                                                    }) => (
                                                                        <Autocomplete
                                                                            sx={{
                                                                                '& .MuiAutocomplete-tag.Mui-selected': {
                                                                                    bgcolor: theme.palette.primary.main,
                                                                                    color: theme.palette.background.paper
                                                                                }
                                                                                // width: '100%'
                                                                            }}
                                                                            onInput={(e) => {
                                                                                // !! MOBILE KEYBOARD BUG FIX
                                                                                const key = e.nativeEvent.data;
                                                                                if (key === ',' && e.target.value !== '') {
                                                                                    e.preventDefault();
                                                                                    let insertedValue = e.target.value.replace(',', '');
                                                                                    setFormTopics([
                                                                                        ...formTopics,
                                                                                        { title: insertedValue, freeTopic: true }
                                                                                    ]);
                                                                                    e.target.value = '';
                                                                                    insertedValue = '';
                                                                                }
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                // disallow symbols and numbers
                                                                                if (e.key.match(/[^a-zA-Z\s]/g)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                                if (e.key === ',' && e.target.value === '') {
                                                                                    e.preventDefault();
                                                                                }
                                                                                if (e.key === ',' && e.target.value !== '') {
                                                                                    e.preventDefault();
                                                                                    let insertedValue = e.target.value.replace(',', '');
                                                                                    setFormTopics([
                                                                                        ...formTopics,
                                                                                        { title: insertedValue, freeTopic: true }
                                                                                    ]);
                                                                                    e.target.value = '';
                                                                                    insertedValue = '';
                                                                                    // press enter key
                                                                                } else if (e.key === 'Enter' && e.target.value !== '') {
                                                                                    e.preventDefault();
                                                                                    let insertedValue = e.target.value;
                                                                                    setFormTopics([
                                                                                        ...formTopics,
                                                                                        { title: insertedValue, freeTopic: true }
                                                                                    ]);
                                                                                    e.target.value = '';
                                                                                    insertedValue = '';
                                                                                }
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (e.target.value !== '') {
                                                                                    let insertedValue = e.target.value;
                                                                                    setFormTopics([
                                                                                        ...formTopics,
                                                                                        { title: insertedValue, freeTopic: true }
                                                                                    ]);
                                                                                    e.target.value = '';
                                                                                    insertedValue = '';
                                                                                }
                                                                            }}
                                                                            onKeyUp={(e) => {
                                                                                if (e.key === ',' && e.target.value !== '') {
                                                                                    e.preventDefault();
                                                                                    e.target.value = '';
                                                                                }
                                                                                // * a bit buggy
                                                                                // if (e.key === 'Backspace' && e.target.value === '') {
                                                                                //     e.preventDefault();
                                                                                //     const newFormTopics = [...formTopics];
                                                                                //     newFormTopics.pop();
                                                                                //     setFormTopics(newFormTopics);
                                                                                // }
                                                                            }}
                                                                            fullWidth
                                                                            multiple
                                                                            freeSolo
                                                                            variant="standard"
                                                                            limitTags={5}
                                                                            id="multiple-limit-tags"
                                                                            options={[]}
                                                                            value={[...pinnedModules, ...formTopics] || []}
                                                                            getOptionLabel={(option) => option.title}
                                                                            defaultValue={pinnedModules || []}
                                                                            renderTags={(value, getTagProps) =>
                                                                                value.map((option, index) => (
                                                                                    <Chip
                                                                                        label={option.title}
                                                                                        {...getTagProps({ index })}
                                                                                        icon={
                                                                                            <ModuleIcon
                                                                                                appModule={option?.icona}
                                                                                                iconFill={theme.palette.background.paper}
                                                                                                size={0.65}
                                                                                            />
                                                                                        }
                                                                                        onDelete={(e) => {
                                                                                            if (
                                                                                                pinnedModules.find(
                                                                                                    (item) => item.slug === option.slug
                                                                                                )
                                                                                            ) {
                                                                                                setPinnedModules(
                                                                                                    pinnedModules.filter(
                                                                                                        (item) => item.slug !== option.slug
                                                                                                    )
                                                                                                );
                                                                                                setCookie(
                                                                                                    'modules',
                                                                                                    pinnedModules.filter(
                                                                                                        (item) => item.slug !== option.slug
                                                                                                    ),
                                                                                                    {
                                                                                                        path: '/',
                                                                                                        expires: new Date(
                                                                                                            Date.now() +
                                                                                                                1000 * 60 * 60 * 24 * 10
                                                                                                        )
                                                                                                    }
                                                                                                );

                                                                                                if (
                                                                                                    navigator.setAppBadge ||
                                                                                                    navigator.clearAppBadge
                                                                                                ) {
                                                                                                    navigator.setAppBadge(
                                                                                                        pinnedModules.length
                                                                                                    );
                                                                                                    // navigator.clearAppBadge();
                                                                                                }
                                                                                            } else {
                                                                                                setFormTopics(
                                                                                                    formTopics.filter(
                                                                                                        (item) => item.slug !== option.slug
                                                                                                    )
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        sx={{
                                                                                            '& .MuiChip-label': {
                                                                                                paddingLeft: option?.freeTopic
                                                                                                    ? '.5rem'
                                                                                                    : '0',
                                                                                                lineHeight: '1.25rem'
                                                                                            },
                                                                                            borderRadius: '0.5rem',
                                                                                            fontSize: '1rem',
                                                                                            marginBottom: '-.5rem',
                                                                                            fontWeight: 400,
                                                                                            bgcolor: option?.freeTopic
                                                                                                ? theme.palette.primary[200]
                                                                                                : theme.palette.primary.main,
                                                                                            color: option?.freeTopic
                                                                                                ? theme.palette.primary.main
                                                                                                : theme.palette.background.paper
                                                                                        }}
                                                                                    />
                                                                                ))
                                                                            }
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    // sx={InputProps}
                                                                                    FormHelperTextProps={helperTextProps}
                                                                                    fullWidth
                                                                                    sx={{
                                                                                        ...InputProps,
                                                                                        mb: '1.5rem',
                                                                                        fontSize: '1.25rem',
                                                                                        '& .MuiAutocomplete-inputRoot': {
                                                                                            fontSize: '1.25rem',
                                                                                            fontWeight: 400,
                                                                                            padding: 0,
                                                                                            margin: 0,
                                                                                            marginTop: '2.5rem'
                                                                                        },
                                                                                        // placeholder
                                                                                        '& .MuiAutocomplete-input': {
                                                                                            fontSize: '1.25rem',
                                                                                            fontWeight: 400,
                                                                                            '&::placeholder': {
                                                                                                fontSize: '1.25rem',
                                                                                                fontWeight: 400
                                                                                            }
                                                                                        }
                                                                                        // '& .MuiChip-label': {
                                                                                        //     paddingLeft: '0',
                                                                                        //     lineHeight: '1.25rem'
                                                                                        // }
                                                                                    }}
                                                                                    value={value}
                                                                                    onChange={onChange}
                                                                                    error={!!error}
                                                                                    helperText={error ? error.message : null}
                                                                                    variant="standard"
                                                                                    label="Quali sono gli argomenti di tuo interesse?"
                                                                                    placeholder="Aggiungi degli argomenti di interesse separati da virgola"
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                />
                                                                <Controller
                                                                    name="messageBody"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    FormHelperTextProps={helperTextProps}
                                                                    value={messageBody}
                                                                    rules={{
                                                                        required: 'È necessario inserire un messaggio',
                                                                        minLength: { value: 10, message: 'Minimo caratteri: 10' },
                                                                        maxLength: { value: 1000, message: 'Massimo caratteri: 1000' },
                                                                        validate: true
                                                                    }} // onChange={(e) => {
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                        fieldState: { invalid, isTouched, isDirty, error }
                                                                    }) => (
                                                                        <TextField
                                                                            sx={InputProps}
                                                                            fullWidth
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            error={!!error}
                                                                            helperText={error ? error.message : null}
                                                                            type="textarea"
                                                                            multiline
                                                                            rows={4}
                                                                            // minRows={4}
                                                                            // maxRows={10}
                                                                            name={name}
                                                                            variant="standard"
                                                                            label="Scrivi qui il tuo messaggio"
                                                                            placeholder="Inserisci un messaggio"
                                                                        />
                                                                    )}
                                                                />

                                                                <Controller
                                                                    name="policyConsent"
                                                                    control={control}
                                                                    defaultValue={false}
                                                                    value={policyConsent}
                                                                    rules={{
                                                                        required: 'È necessario accettare la policy',
                                                                        validate: true
                                                                    }} // onChange={(e) => {
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name, ref },
                                                                        fieldState: { invalid, isTouched, isDirty, error }
                                                                    }) => (
                                                                        <Box
                                                                            sx={{
                                                                                // width: matchUpMd ? '100%' : '80vw',
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-start',
                                                                                textAlign: 'left',
                                                                                width: '100%',
                                                                                mt: '2rem'

                                                                                // margin: '0 auto'
                                                                            }}
                                                                        >
                                                                            <FormControlLabel
                                                                                // error={!!error}
                                                                                sx={{
                                                                                    color: error
                                                                                        ? theme.palette.error.main
                                                                                        : theme.palette.text.primary
                                                                                }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={value}
                                                                                        onChange={onChange}
                                                                                        color={error ? 'error' : 'primary'}
                                                                                        name={name}
                                                                                    />
                                                                                }
                                                                                label="Dichiaro di aver preso visione dell'informativa sulla privacy e presto il mio consenso al trattamento dei dati personali per finalità di marketing."
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </>
                                                        )}
                                                    </Box>
                                                </Box>
                                                {matchUpMd &&
                                                    (activeStep !== maxSteps - 1 ? (
                                                        <Button
                                                            disableElevation
                                                            sx={{
                                                                width: '3rem',
                                                                height: '3rem',
                                                                minHeight: 0,
                                                                maxHeight: '3rem',
                                                                maxWidth: '3rem',
                                                                minWidth: '3rem',
                                                                p: 0,
                                                                borderRadius: '50%'
                                                            }}
                                                            // onMouseEnter={(e) => setIsHoveringElement(e.target)}
                                                            // onMouseLeave={(e) => setIsHoveringElement(null)}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                const submitted = handleSubmit(onStepChangeForward);
                                                                submitted();
                                                            }}
                                                            disabled={activeStep === maxSteps - 1}
                                                        >
                                                            {/* {isHoveringElement && 'Invia'} */}
                                                            <ArrowRight size={24} color={theme.palette.background.paper} />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            disableElevation
                                                            type="submit"
                                                            sx={{
                                                                width: '3rem',
                                                                height: '3rem',
                                                                minHeight: 0,
                                                                maxHeight: '3rem',
                                                                maxWidth: '3rem',
                                                                minWidth: '3rem',
                                                                p: 0,
                                                                borderRadius: '50%'
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={
                                                                Object.keys(errors).length !== 0 ||
                                                                status === 'success' ||
                                                                status === 'error' ||
                                                                status === 'loading'
                                                                // || createCtx?.loading
                                                            }
                                                        >
                                                            <MailAll size={24} color={theme.palette.background.paper} />
                                                        </Button>
                                                    ))}
                                                {!matchUpMd && (
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            px: '2rem',
                                                            mt: '2rem'
                                                        }}
                                                    >
                                                        <Button
                                                            disableElevation
                                                            sx={{
                                                                width: '3rem',
                                                                height: '3rem',
                                                                maxHeight: '3rem',
                                                                maxWidth: '3rem',
                                                                minWidth: '3rem',
                                                                p: 0,
                                                                borderRadius: '50%'
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleBack}
                                                            disabled={activeStep === 0}
                                                        >
                                                            <ArrowLeft size={24} />
                                                        </Button>
                                                        {activeStep !== maxSteps - 1 ? (
                                                            <Button
                                                                disableElevation
                                                                sx={{
                                                                    width: '3rem',
                                                                    height: '3rem',
                                                                    minHeight: 0,
                                                                    maxHeight: '3rem',
                                                                    maxWidth: '3rem',
                                                                    minWidth: '3rem',
                                                                    p: 0,
                                                                    borderRadius: '50%'
                                                                }}
                                                                // onMouseEnter={(e) => setIsHoveringElement(e.target)}
                                                                // onMouseLeave={(e) => setIsHoveringElement(null)}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                    const submitted = handleSubmit(onStepChangeForward);
                                                                    submitted();
                                                                }}
                                                                disabled={activeStep === maxSteps - 1}
                                                            >
                                                                {/* {isHoveringElement && 'Invia'} */}
                                                                <ArrowRight size={24} color={theme.palette.background.paper} />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                disableElevation
                                                                onClick={() => console.log(status)}
                                                                type="submit"
                                                                sx={{
                                                                    width: '3rem',
                                                                    height: '3rem',
                                                                    minHeight: 0,
                                                                    maxHeight: '3rem',
                                                                    maxWidth: '3rem',
                                                                    minWidth: '3rem',
                                                                    p: 0,
                                                                    borderRadius: '50%'
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={
                                                                    Object.keys(errors).length !== 0 ||
                                                                    status === 'success' ||
                                                                    status === 'error' ||
                                                                    status === 'loading'
                                                                }
                                                            >
                                                                {status === 'loading' ? (
                                                                    <CircularProgress size={24} />
                                                                ) : (
                                                                    <MailAll size={24} color={theme.palette.background.paper} />
                                                                )}
                                                            </Button>
                                                        )}
                                                    </Box>
                                                )}
                                            </FormContainer>
                                        )}
                                        {isFormSent && status === 'sending' && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'space-between',
                                                    height: '100%',
                                                    width: '100%',
                                                    px: '2rem'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontWeight: 600,
                                                        color: theme.palette.primary.main,
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    <Time size={16} />
                                                    &nbsp;&nbsp;Attendi, invio in corso
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        my: '2rem'
                                                    }}
                                                >
                                                    <CircularProgress size={24} />
                                                </Box>
                                            </Box>
                                        )}
                                        {/* {status} */}
                                        {status === 'error' ||
                                            (isErrored && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'space-between',
                                                        height: '100%',
                                                        width: '100%',
                                                        px: '2rem'
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            fontWeight: 600,
                                                            color: theme.palette.primary.main,
                                                            textTransform: 'uppercase'
                                                        }}
                                                    >
                                                        <Error size={16} />
                                                        &nbsp;&nbsp;Si è verificato un errore
                                                    </Typography>
                                                    <Typography
                                                        color={theme.palette.primary.main}
                                                        sx={{
                                                            pt: '2rem',
                                                            fontWeight: 300,
                                                            fontSize: matchUpMd ? '2rem' : '1.25rem'
                                                        }}
                                                    >
                                                        Si è verificato un errore durante l&apos;invio della richiesta. Riprova più tardi.
                                                    </Typography>
                                                </Box>
                                            ))}
                                        {isFormSent && !isErrored && status === 'success' && (
                                            // && isFormSent
                                            // && status === 'success'
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'space-between',
                                                    height: '100%',
                                                    width: '100%',
                                                    px: '2rem'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontWeight: 600,
                                                        color: theme.palette.primary.main,
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    <Checkmark size={16} />
                                                    &nbsp;&nbsp;Richiesta inviata con successo
                                                </Typography>
                                                <Typography
                                                    color={theme.palette.primary.main}
                                                    sx={{
                                                        pt: '2rem',
                                                        fontWeight: 300,
                                                        fontSize: matchUpMd ? '2rem' : '1.25rem'
                                                    }}
                                                >
                                                    Gentile {formData?.fname}
                                                    {formData?.lname !== '' && ` ${formData?.lname}`}, grazie per averci contattato,
                                                    prenderemo in carico la tua richiesta e ti risponderemo il prima possibile!
                                                </Typography>
                                                {modules && modules.length > 0 && (
                                                    <Typography
                                                        color={theme.palette.primary.main}
                                                        sx={{
                                                            pt: '2rem',
                                                            fontWeight: 300,
                                                            fontSize: matchUpMd ? '2rem' : '1.25rem'
                                                        }}
                                                    >
                                                        Hai anche richiesto una brochure personalizzata che riceverai sulla email che ci hai
                                                        fornito.{' '}
                                                        <PDFDownloadLink
                                                            style={{
                                                                textDecoration: 'none',
                                                                color: theme.palette.primary.main
                                                                // pointerEvents: 'none'
                                                            }}
                                                            document={
                                                                <CustomBrochure
                                                                    mods={modules}
                                                                    recipientName={`${formData?.fname} ${formData?.lname}`}
                                                                    generationDate={new Date().toLocaleDateString('it-IT', {
                                                                        day: 'numeric',
                                                                        month: 'long',
                                                                        year: 'numeric'
                                                                    })}
                                                                />
                                                            }
                                                            fileName={generateFileName()}
                                                        >
                                                            {({ blob, url, loading, error }) => {
                                                                if (loading) return <span>Attendi per il download..</span>;
                                                                if (error) return '';
                                                                return (
                                                                    <span>
                                                                        Se vuoi scaricarla subito,{' '}
                                                                        <span
                                                                            style={{
                                                                                textDecoration: 'underline'
                                                                            }}
                                                                        >
                                                                            clicca qui
                                                                        </span>
                                                                    </span>
                                                                );
                                                            }}
                                                        </PDFDownloadLink>
                                                        .
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </form>
                            </FormProvider>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

CustomContactForm.propTypes = {
    status: PropTypes.string,
    message: PropTypes.string,
    onValidated: PropTypes.func,
    popup: PropTypes?.bool
};

ContactForm.propTypes = {
    isPopup: PropTypes?.bool
};

export default ContactForm;
