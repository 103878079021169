import { UserContext } from 'context/UserContext';
import { lazy, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
// material-ui
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// project imports
import { gql, useQuery } from '@apollo/client';
import { SET_MENU } from 'store/actions';
import { drawerWidth } from 'store/constant';
import { GET_ROUTE } from 'utils/queries';
// import QSERV_ENDPOINT from '../../constants';

const Header = Loadable(lazy(() => import('./Header')));
const Sidebar = Loadable(lazy(() => import('./Sidebar')));

// assets

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    background: theme.palette.grey[50],
    margin: 0,
    marginTop: '4rem',
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    // minHeight: 'calc(100vh - 88px)',
    // background: theme.palette.grey[100],
    // width: `calc(100% - ${drawerWidth}px)`, // height: `calc(100% - 72px)`,
    padding: '0',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `100%`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            marginRight: 0
            // maxWidth: `1366px`
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: -drawerWidth,
            marginRight: 0
            // maxWidth: `1366px`
            // width: `100%`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: -drawerWidth,
            marginRight: 0,
            // maxWidth: `1366px`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            // maxWidth: '100vw',
            // width: `100%`,
            // padding: '16px',
            marginRight: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            // maxWidth: '100vw',
            // width: `100%`,
            // padding: '16px',
            marginRight: 0
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0
            // maxWidth: `1366px`
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: '100%'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //
const UserVerify = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { adminid } = useParams();
    const verifyUser = useCallback(() => {
        fetch(`${process.env.PUBLIC_URL}/users/refresh`, {
            // fetch(`http://localhost:3000/users/refresh`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(async (response, error) => {
                if (response && response.ok) {
                    const responseData = await response.json();
                    // console.log(responseData);
                    setUserContext((oldValues) => ({ ...oldValues, token: responseData.token }));
                    // if (data && data.token) {
                    if (!userContext.user) {
                        try {
                            fetch(`${process.env.PUBLIC_URL}/users/me`, {
                                // fetch(`http://localhost:3000/users/me`, {
                                method: 'GET',
                                credentials: 'include',
                                // Pass authentication token as bearer token in header
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${responseData.token}`
                                }
                            }).then(async (response) => {
                                if (response.ok) {
                                    const meData = await response.json();
                                    // console.log('MEDATA', meData);

                                    setUserContext((oldVals) => ({ ...oldVals, user: meData }));
                                } else if (response && response.status === 500) {
                                    setUserContext((oldVals) => ({ ...oldVals, token: null, user: null }));

                                    navigate(`/admin/${adminid}/login`, { replace: true });
                                    // clearTimeout(verifyUser);
                                } else if (response && response.status === 401) {
                                    // console.log('401');
                                    if (userContext.token === null) {
                                        setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));

                                        navigate(`/admin/${adminid}/login`, { replace: true });
                                        // clearTimeout(verifyUser);
                                    }
                                } else {
                                    setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));

                                    navigate(`/admin/${adminid}/login`, { replace: true });
                                    // clearTimeout(verifyUser);
                                }
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                } else if (response && response.status === 500) {
                    setUserContext((oldVals) => ({ ...oldVals, token: null, user: null }));
                    navigate(`/admin/${adminid}/login`, { replace: true });

                    // clearTimeout(verifyUser);
                } else if (response.status === 401) {
                    // if (userContext.token === null) {
                    setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));
                    navigate(`/admin/${adminid}/login`, { replace: true });
                    // clearTimeout(verifyUser);
                    // }
                }

                setTimeout(verifyUser, 1 * 60 * 1000);
            })
            .catch((err) => {
                // siilently drop the error
                console.log();
                setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));

                navigate(`/admin/${adminid}/login`, { replace: true });
                // clearTimeout(verifyUser);
            });
    }, [setUserContext, navigate, userContext, adminid]);

    // useEffect(() => {
    if (!userContext.user || !userContext.token) {
        verifyUser();
    }
    // }, [verifyUser, userContext]);

    return verifyUser;
};
const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [userContext, setUserContext] = useContext(UserContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { adminid } = useParams();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const { data, error, loading } = useQuery(GET_ROUTE, { variables: { id: adminid } });
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // dispatch({ type: SET_MENU, opened: false });

        if (data && data.protectedRoute && data.protectedRoute !== null) {
            // do nothing, let the user stay on the page
        } else if (error || (data && data?.protectedRoute === null)) {
            console.log('errors', error);

            navigate('/404', { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd, data?.protectedRoute, error]);
    UserVerify();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    boxShadow: '0px 8x 16px 0px rgba(0,0,0,1)',
                    zIndex: 1000,

                    top: 0,
                    left: 0,
                    height: '4rem',
                    // bgcolor: theme.palette.primary[200],
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar
                    sx={{
                        bgcolor: theme.palette.primary[200],
                        height: '4rem',
                        // bgcolor: route.pathname === '/' ? theme.palette.primary.main : theme.palette.background.paper,
                        // color: theme.palette.primary.main,
                        // bgcolor: isRouteHome ? theme.palette.primary.main : theme.palette.background.paper,
                        transition: 'all .3s ease-in-out'
                    }}
                >
                    {/* {userContext && userContext?.user && ( */}
                    <Header
                        param={adminid}
                        context={userContext}
                        setContext={setUserContext}
                        handleLeftDrawerToggle={handleLeftDrawerToggle}
                    />
                    {/* // )} */}
                </Toolbar>
            </AppBar>

            {/* drawer */}
            {/* {userContext && userContext?.user &&  */}
            <Sidebar
                visibilityHandler={userContext?.user?.identity?.Ruolo}
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />
            {/* } */}

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                <Outlet context={[userContext, setUserContext]} />
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};
export default MainLayout;
