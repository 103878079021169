// material-ui
import LinearProgress from '@mui/material/LinearProgress';
import { styled, useTheme } from '@mui/material/styles';

// styles

// ==============================|| LOADER ||============================== //
const Loader = () => {
    const theme = useTheme();
    const LoaderWrapper = styled('div')({
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99999999999999,
        // width: '100%'
        width: '100%',
        height: '4px'
        // background: theme.palette.primary.main
    });
    return (
        <LoaderWrapper>
            <LinearProgress color="primary" sx={{ height: '100%' }} variant="indeterminate" />
        </LoaderWrapper>
    );
};

export default Loader;
