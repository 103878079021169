import { lazy, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { UserContext } from 'context/UserContext';
import { BrowserView, MobileView } from 'react-device-detect';
import Loadable from 'ui-component/Loadable';
import { useQuery } from '@apollo/client';
import { GET_ROUTE } from 'utils/queries';

// const Footer = Loadable(lazy(() => import('./FrontendFooter')));
const Header = Loadable(lazy(() => import('../FrontendLayout/FrontendHeader')));

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, menuIsOpen, route }) => ({
    ...theme.typography.mainContent,
    overflowX: 'hidden',
    marginTop: '3rem',
    background: theme.palette.background.paper,
    minHeight: 'calc(100vh - 3rem)'
}));

// ==============================|| MAIN LAYOUT ||============================== //

const AuthLayout = () => {
    const theme = useTheme();
    const route = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    // const { adminid } = useParams();
    // const { data, error, loading } = useQuery(GET_ROUTE, { variables: { id: adminid } });

    // Handle compressed navbar
    const [isCompressed, setCompressed] = useState(false);
    // const [socials, setSocials] = useState(null);
    const [userContext, setUserContext] = useState(UserContext);
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const navigate = useNavigate();

    const verifyUser = useCallback(() => {
        fetch(`${process.env.PUBLIC_URL}/users/refresh`, {
            // fetch(`http://localhost:3000/users/refresh`, {
            method: 'POST',
            credentials: 'include',
            // Pass authentication token as bearer token in header
            headers: {
                'Content-Type': 'application/json'
                // Authorization: `Bearer ${responseData.token}`
            }
        })
            .then(async (response) => {
                if (response && response.ok) {
                    try {
                        const data = await response.json();
                        setUserContext((oldValues) => ({ ...oldValues, token: data.token }));

                        // call refreshToken every 5 minutes to renew the authentication token.
                        // setTimeout(verifyUser, 5 * 60 * 1000);
                        // console.log('verifyUser', userContext);
                        // setTimeout(verifyUser, 5 * 60 * 1000);
                    } catch (err) {
                        // console.log('ERR', err);
                    }
                } else if (response && response.status === 401) {
                    // setUserContext((oldValues) => ({ ...oldValues, token: null }));
                    // console.log('401', response);
                } else {
                    // setUserContext((oldValues) => ({ ...oldValues, token: null }));
                }
            })
            .catch((err) => {
                // siilently drop the error
                console.log();
                setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));
            });
    }, [setUserContext]);

    useEffect(() => {
        if (!userContext.token) {
            verifyUser();
        }
    }, [setUserContext, userContext.token, verifyUser]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                border: 'none !important',
                width: '100%'
            }}
        >
            <CssBaseline />

            <MobileView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 10000,
                        height: '3rem',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        // overflowX: 'hidden',,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                        boxShadow: !leftDrawerOpened ? '0px 5px 20px rgba(0, 0, 0, .1)' : 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            height: '3rem',
                            px: '0 !important',
                            bgcolor: theme.palette.primary.main,
                            // backdropFilter: isCompressed ? 'blur(.74rem) ' : 'none',
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            // vizHandler
                            // routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            // styleHandler={
                            //     route.pathname === '/' || isCompressed || leftDrawerOpened || rightArchiveOpened
                            //         ? theme.palette.background.paper
                            //         : theme.palette.primary.main
                            // }
                            // isCompressed={leftDrawerOpened ? true : isCompressed}
                            // handleLeftDrawerToggle={handleLeftDrawerToggle}
                            // handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </MobileView>
            <BrowserView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        // leftDrawerOpened
                        zIndex: 100,
                        height: '3rem',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        // overflowX: 'hidden',,
                        // transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                        boxShadow: '0px 5px 20px rgba(0, 0, 0, .1)'
                    }}
                >
                    <Toolbar
                        sx={{
                            height: '3rem',
                            px: '0 !important',
                            bgcolor: theme.palette.primary.main,
                            backdropFilter: isCompressed ? 'blur(.74rem) ' : 'none',
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            // vizHandler
                            // routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            // styleHandler={
                            //     route.pathname === '/' || isCompressed || leftDrawerOpened
                            //         ? theme.palette.background.paper
                            //         : theme.palette.primary.main
                            // }
                            // isCompressed={leftDrawerOpened ? true : isCompressed}
                            // handleLeftDrawerToggle={handleLeftDrawerToggle}
                        />
                    </Toolbar>
                </AppBar>
            </BrowserView>
            <Main theme={theme} open={leftDrawerOpened} route={route}>
                <Outlet context={[userContext, setUserContext]} />
            </Main>
        </Box>
    );
};

export default AuthLayout;
