import { useRoutes } from 'react-router-dom';

import RouteStructure from './RouteStructure';

import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //
export function PublicRoutes() {
    return useRoutes([RouteStructure], config.basename);
}
