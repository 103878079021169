import { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { gql, useQuery } from '@apollo/client';
import { AppBar, Box, CssBaseline, Drawer, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';
import { SET_MENU, SET_CONTACT_FORM } from 'store/actions';
import Loadable from 'ui-component/Loadable';
import ContactForm from 'ui-component/home/ContactForm';
import { Close } from '@carbon/icons-react';
import { useTransition } from 'react-spring';

// const Footer = Loadable(lazy(() => import('./FrontendFooter')));
const Header = Loadable(lazy(() => import('../FrontendLayout/FrontendHeader')));
const Sidebar = Loadable(lazy(() => import('../FrontendLayout/FullMenu')));

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, menuIsOpen, route }) => ({
    ...theme.typography.mainContent,
    overflowX: 'hidden',
    marginTop: '3rem',
    background: theme.palette.background.paper,
    minHeight: 'calc(100vh - 3rem)'
}));

// ==============================|| MAIN LAYOUT ||============================== //

const NotFoundLayout = () => {
    const theme = useTheme();
    const route = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    // Handle compressed navbar
    const [isCompressed, setCompressed] = useState(false);
    // const [socials, setSocials] = useState(null);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const contactFormOpened = useSelector((state) => state.customization.contactFormOpened);
    // const rightArchiveOpened = useSelector((state) => state.customization.archiveOpened);
    // const leftDrawerClosed = useSelector((state) => state.customization.closed);

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                border: 'none !important',
                width: '100%'
            }}
        >
            <CssBaseline />

            <MobileView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 10000,
                        height: '3rem',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        // overflowX: 'hidden',,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                        boxShadow: !leftDrawerOpened ? '0px 5px 20px rgba(0, 0, 0, .1)' : 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            height: '3rem',
                            px: '0 !important',
                            bgcolor: theme.palette.primary.main,
                            // backdropFilter: isCompressed ? 'blur(.74rem) ' : 'none',
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            // vizHandler
                            // routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            // styleHandler={
                            //     route.pathname === '/' || isCompressed || leftDrawerOpened || rightArchiveOpened
                            //         ? theme.palette.background.paper
                            //         : theme.palette.primary.main
                            // }
                            // isCompressed={leftDrawerOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            // handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </MobileView>
            <BrowserView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        // leftDrawerOpened
                        zIndex: 100,
                        height: '3rem',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        // overflowX: 'hidden',,
                        // transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                        boxShadow: '0px 5px 20px rgba(0, 0, 0, .1)'
                    }}
                >
                    <Toolbar
                        sx={{
                            height: '3rem',
                            px: '0 !important',
                            bgcolor: theme.palette.primary.main,
                            backdropFilter: isCompressed ? 'blur(.74rem) ' : 'none',
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            // vizHandler
                            // routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            // styleHandler={
                            //     route.pathname === '/' || isCompressed || leftDrawerOpened
                            //         ? theme.palette.background.paper
                            //         : theme.palette.primary.main
                            // }
                            // isCompressed={leftDrawerOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                        />
                    </Toolbar>
                </AppBar>
            </BrowserView>
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            <Main theme={theme} open={leftDrawerOpened} route={route}>
                {/* breadcrumb */}
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet />
            </Main>
            {/* <Footer isOverlayOpened={leftDrawerOpened} /> */}
            <Drawer
                sx={{
                    position: 'relative',
                    height: '100vh',
                    '& .MuiModal-backdrop': {
                        backgroundColor: `rgba(15, 76, 129, 0.8)`
                    },
                    '& .MuiDrawer-paper': {
                        height: matchUpMd ? 'auto' : 'calc(100% - 3rem)',

                        width: '100%',
                        background: theme.palette.primary.light
                    }
                }}
                anchor="bottom"
                open={contactFormOpened}
                onClose={() => dispatch({ type: SET_CONTACT_FORM, contactFormOpened: false })}
            >
                <ContactForm isPopup />
                <Box
                    sx={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none',
                        zIndex: '1000',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <IconButton
                        variant="contained"
                        color="primary"
                        sx={{
                            pointerEvents: 'auto',
                            background: theme.palette.primary.main,
                            color: theme.palette.background.paper,
                            width: '3rem',
                            height: '3rem',
                            borderRadius: 0
                        }}
                        onClick={() => {
                            dispatch({ type: SET_CONTACT_FORM, contactFormOpened: false });
                        }}
                    >
                        <Close size={24} />
                    </IconButton>
                </Box>
            </Drawer>
            {/* <Customization /> */}
        </Box>
    );
};

export default NotFoundLayout;
